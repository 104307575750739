import React, { FC, useCallback } from 'react';
import type { IconProps } from 'components/Icons/IconProps';
import { Legacy_MenuItem } from 'modules/Sidebar/components/Menu/Menu';
import { Button } from 'components/Button';
import { CollapsableItem } from 'components/CollapsibleItem';
import { IconDelete, IconAutoAnnotation, IconDropArrowUp, IconEvaluations, IconModelImport, IconModuleManager } from 'components/Icons';
import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { submenuIcon } from 'modules/Sidebar/Sidebar.styles';
import { uninstallBtn } from './styles';

interface ModuleVersionsListProps {
  installedModuleVersionId: string | undefined;
  appHref: (path?: string) => string;
  isCollapsed?: boolean;
  imvList: CF.API.Modules.InstalledModuleVersion[];
  handleUninstall?: ((moduleId: string) => void) | false;
}

const defaultIcons: Record<string, FC<IconProps>> = {
  module_manager_install: IconModuleManager,
  auto_annotation_install: IconAutoAnnotation,
  model_import_install: IconModelImport,
};

const ModuleIcon: FC<{ moduleId: string }> = ({ moduleId }) => {
  const Icon = defaultIcons[moduleId] || IconEvaluations;
  return <Icon size={22} />;
};

export const ModuleVersionsList: FC<ModuleVersionsListProps> = ({ isCollapsed, installedModuleVersionId, appHref, imvList, handleUninstall }) => {
  const renderMenuText = useCallback((text) => (!isCollapsed ? text : ''), [isCollapsed]);
  const onClickUninstall = handleUninstall && ((moduleId: string) => () => handleUninstall(moduleId));

  return (
    <>
      {imvList.map((moduleVersion) => (
        <div key={`${moduleVersion.id}_outer`}>
          <CollapsableItem
            key={`${moduleVersion.id}-${moduleVersion.module_version.id}_collapsable`}
            title={
              <Legacy_MenuItem
                className="sidebar-menu-item"
                key={`${moduleVersion.module_version.id}_menu`}
                icon={<ModuleIcon moduleId={moduleVersion.id} />}
                isCollapsed={isCollapsed}
                title={moduleVersion.module_version.module_nav.title}
              >
                {renderMenuText(moduleVersion.module_version.module_nav.title)}
              </Legacy_MenuItem>
            }
            opened={Boolean(installedModuleVersionId === moduleVersion.id)}
            showCaret={!isCollapsed}
          >
            {moduleVersion.module_version.module_nav.module_sub_navs.map((subNav) => (
              <div key={subNav.title} className={cx(flex.init, flex.justifyContent.center)}>
                <Legacy_MenuItem
                  icon={<IconDropArrowUp className={submenuIcon} size={24} />}
                  className={!isCollapsed ? 'menu-subnav' : 'sidebar-menu-item'}
                  key={subNav.title}
                  href={appHref(`/installed_module_versions/${moduleVersion.id}/${subNav.query_value ? subNav.query_value : ''}`)}
                  isCollapsed={isCollapsed}
                  title={subNav.title}
                >
                  {renderMenuText(subNav.title)}
                </Legacy_MenuItem>
              </div>
            ))}

            {onClickUninstall && (
              <div className={uninstallBtn} key={`${moduleVersion.module_version.id}_btn_div`}>
                <Button
                  size="md"
                  variant="link"
                  value={moduleVersion.id}
                  className={cx(flex.init, flex.justifyContent.center)}
                  onClick={onClickUninstall(moduleVersion.id)}
                  data-testid="delete-btn"
                >
                  <IconDelete data-testid="manage" size={20} color="#344054" className="uninstall" />
                  Uninstall Module
                </Button>
              </div>
            )}
          </CollapsableItem>
        </div>
      ))}
    </>
  );
};
