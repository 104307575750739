import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const fetchInvitationTE = <E = Error>(
  { invitationId }: { invitationId: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Organizations.FetchInviteResponse> =>
  fetchTE<CF.API.Organizations.FetchInviteResponse, E>(
    {
      path: `/api/_api/v2/organization_invitation/${invitationId}`,
      method: 'GET',
    },
    onRejected,
  );

export const acceptInvitationTE = <E = Error>(
  { invitationId, userId }: { invitationId: string; userId: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/organization_invitation_accept/${invitationId}`,
      method: 'POST',
    },
    onRejected,
  );

export const declineInvitationTE = <E = Error>(
  { invitationId, userId }: { invitationId: string; userId: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/organization_invitation_decline/${invitationId}`,
      method: 'POST',
    },
    onRejected,
  );
