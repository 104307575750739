import { useCallback } from 'react';
import { cx } from '@linaria/core';
import { Form, Formik } from 'formik';
import { string, object } from 'yup';
import { Button } from 'components/ui_library/Button';
import { InputField } from 'components/Formik/InputField';
import { SelectField } from 'components/Formik/SelectField';
import { SelectOption } from 'components/Select';
import { IconCloseSquare } from 'components/Icons';
import { Col, Row } from 'components/Grid';
import { titles } from 'styles/typography';
import { TOrganizationsMST } from 'modules/Organizations/mstStores/OrganizationMST';
import { flex } from 'styles/utils';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { cssHelpers } from 'styles/utils/core';
import { dialogWrapper, formContainer, formGroup } from './Modals.styles';
import { useRoles } from '../../hooks';
type Props = {
  store: TOrganizationsMST;
  onClose: () => void;
};
interface FormData {
  invitee_email: string;
  role: SelectOption;
}
const validationSchema = object().shape({
  invitee_email: string().email().required('Required'),
  role: object().shape({
    name: string().required('Required'),
    value: string().required('Required')
  })
});
export const InviteModal = ({
  store,
  onClose,
  ...rest
}: Props): JSX.Element => {
  const roles = useRoles(1);
  const orgId = useCurrentUserId();
  const onSubmit = useCallback(({
    invitee_email,
    role
  }: FormData) => {
    store.sendInvitation({
      orgId,
      memberEmail: invitee_email,
      memberRoleId: role.value
    });
    onClose();
  }, [store, onClose, (rest as {
    appId: string;
  }).appId, orgId]);
  return <div className={dialogWrapper}>
      <div className={cx(flex.init, flex.justifyContent.between, flex.alignItems.center)}>
        <h4 className={titles}>Invite Member</h4>

        <Button variant="unstyled" onClick={onClose}>
          <IconCloseSquare size={20} />
        </Button>
      </div>

      <div className={formContainer}>
        <Formik enableReinitialize initialValues={{
        invitee_email: '',
        role: {
          name: 'Select Role',
          value: ''
        }
      }} validationSchema={validationSchema} onSubmit={(values: FormData) => onSubmit(values)}>
          {({
          isSubmitting,
          isValid,
          dirty
        }) => <Form>
              <Row columnSpacing={2}>
                <Col xs={12}>
                  <InputField name="invitee_email" label="Email" placeholder="Enter Email" formGroupClassName={formGroup} required />
                </Col>
                <Col xs={12}>
                  <SelectField name="role" label="Role" placeholder="Select Role" options={roles} formGroupClassName={formGroup} required />
                </Col>
              </Row>
              <div className={cx('buttons', flex.init, flex.alignItems.center, flex.justifyContent.end, flex.gap[1])}>
                <Button size="md" onClick={onClose} variant="tertiary" className={cssHelpers.margin.x[1]}>
                  Cancel
                </Button>
                <Button size="md" type="submit" disabled={isSubmitting || !isValid || !dirty}>
                  Confirm
                </Button>
              </div>
            </Form>}
        </Formik>
      </div>
    </div>;
};