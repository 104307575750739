import { capitalize } from '@mui/material';
import { SupportedListingEntities } from 'modules/Listings/types';
import { getAppId, getUserId } from 'modules/Listings/helpers/entityCardUtils';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { fetchAppResourceCountsTE } from 'api/apps/fetchAppResourceCounts';
import { either, errorToReactLeft, pipe } from 'utils/fp';
import { omit } from 'rambda';
import { formatNumber } from 'utils/number';

export function useAppResourceCountsConditionally(entity: SupportedListingEntities, enabled: boolean = true): CF.API.Apps.AppResourceCounts {
  const appId = getAppId(entity);
  const userOrOrgId = getUserId(entity);

  const { data } = useQueryTE(
    [queryKeys.AppResources, { appId, userOrOrgId }],
    fetchAppResourceCountsTE({ appId, userId: userOrOrgId }, errorToReactLeft),
    { enabled },
  );

  return pipe(
    data,
    either.map(omit(['status'])),
    either.map((resourceCounts) => {
      const resourcesWithZeroCount = Object.keys(resourceCounts).filter(
        (resourceName) => !resourceCounts[resourceName as keyof typeof resourceCounts],
      );
      return omit(resourcesWithZeroCount, resourceCounts);
    }),
    either.getOrElse(() => ({})),
  );
}

export function formatAppResourceCounts(appResourceCounts: CF.API.Apps.AppResourceCounts) {
  return Object.entries(appResourceCounts).map(([resourceName, count]) => {
    const formattedResouceName = capitalize(count > 1 ? resourceName : resourceName.slice(0, -1));
    const formattedCount = formatNumber(count);

    return `${formattedCount} ${formattedResouceName}`;
  });
}
