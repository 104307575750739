import { createContext } from 'react';

export type FeaturedFlagsUnion =
  | 'ModelRandomSample'
  | 'AppAddCollaborators'
  | 'ModelVisualDetector'
  | 'ModelVisualEmbedder'
  | 'LabelerLabelingTasks'
  | 'TrainingDeepTraining'
  | 'ModelAnnotationWriter'
  | 'ModelVisualClassifier'
  | 'OrgCreateOrganization'
  | 'DatastoreAppReindexing'
  | 'DatastoreDataCollector'
  | 'LabelerConsensusReview'
  | 'LabelerLabelSuggestion'
  | 'DatastoreAppDuplication'
  | 'ModelConceptThresholder'
  | 'LabelerMultiPointPolygon'
  | 'LabelerAIAssistedLabeling'
  | 'LabelerLabelingAsAService'
  | 'LabelerTaskAssignmentRule'
  | 'LabelerVideoInterpolation'
  | 'LabelerOptimizedTaskReview'
  | 'TrainingCustomFacialRecognition'
  | 'DatasetManager';

export const FeaturedFlagsContext = createContext({
  // TypeScript will have hard time to determine its type,
  // if we don't cast this array to an array of strings.
  enabledFeatures: [] as FeaturedFlagsUnion[],
});
