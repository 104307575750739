import React, { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import { DEFAULT_ICON_SIZE } from 'components/ModelTypeIcon/constants';

export const TesseractOperatorIcon: FC<IconProps> = ({ color, size = DEFAULT_ICON_SIZE }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8608 3.09601C14.3061 2.88613 13.6938 2.88613 13.1391 3.09601L3.25844 6.83465L2.81747 7.0015V7.47298V18.8095C2.81747 19.7797 3.39398 20.6572 4.28448 21.0424L13.7291 25.1265L14 25.2436L14.2709 25.1265L23.7155 21.0424C24.6059 20.6572 25.1825 19.7797 25.1825 18.8095V7.47298V7.0015L24.7415 6.83465L14.8608 3.09601ZM13.6222 4.37268C13.8656 4.28056 14.1343 4.28056 14.3777 4.37268L22.683 7.51516L18.2954 9.41249C18.1937 9.32658 18.0778 9.25534 17.9506 9.20265L14.502 7.77489C14.1806 7.64179 13.8194 7.64179 13.4979 7.77489L10.0494 9.20265C9.92209 9.25534 9.80631 9.3266 9.70462 9.41249L5.31703 7.51516L13.6222 4.37268ZM9.23894 10.6983L4.18247 8.5117V18.8095C4.18247 19.2353 4.43547 19.6205 4.82626 19.7894L13.3175 23.4612V17.3126L10.0494 15.9595C9.55882 15.7564 9.23894 15.2778 9.23894 14.7468V10.6983ZM13.3175 16.3655L10.3841 15.1511C10.2206 15.0834 10.1139 14.9238 10.1139 14.7468V11.0767L13.3175 12.462V16.3655ZM14.6825 17.3126V23.4612L23.1737 19.7894C23.5644 19.6205 23.8175 19.2353 23.8175 18.8095V8.5117L18.761 10.6983V14.7468C18.761 15.2778 18.4411 15.7564 17.9506 15.9595L14.6825 17.3126ZM17.886 11.0767V14.7468C17.886 14.9238 17.7795 15.0834 17.6158 15.1511L14.6825 16.3656V12.462L17.886 11.0767ZM17.2558 9.86201L14 11.2699L10.7442 9.86203L13.8326 8.58334C13.9398 8.53898 14.0602 8.53898 14.1673 8.58334L17.2558 9.86201Z"
      fill={color}
    />
  </svg>
);
