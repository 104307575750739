import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const updateUserOrgMemberTE = <E = Error>(
  { orgId, userId, memberRoleId }: { orgId: string; userId: string; memberRoleId: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/members`,
      method: 'PATCH',
      body: {
        action: 'overwrite',
        organization_members: [
          {
            id: userId,
            role_id: memberRoleId,
          },
        ],
      },
    },
    onRejected,
  );
