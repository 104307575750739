import { useEffect } from 'react';
import { useRouter } from 'next/router';

type PreventNavigationParams = {
  condition: ((url: string) => boolean) | boolean;
  confirmMessage?: string;
};

const usePreventNavigationOnCondition = ({ condition, confirmMessage = 'Are you sure you want to leave this page?' }: PreventNavigationParams) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      const shouldPreventNavigation = typeof condition === 'function' ? condition(url) : condition;

      if (shouldPreventNavigation) {
        // eslint-disable-next-line no-alert
        const isConfirmed = window.confirm(confirmMessage);
        if (!isConfirmed) {
          router.events.emit('routeChangeError');
        }
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [condition, confirmMessage, router.events]);
};

export default usePreventNavigationOnCondition;
