import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export type NewPasswordPayload = {
  old_password: string;
  password: string;
};

export type UserBody = {
  info?: {
    user_id?: string;
    first_name?: string;
    last_name?: string;
    company_name?: string;
    country?: string;
    job_role?: string;
    job_title?: string;
    state?: string;
    intention?: string;
    visibility?: CF.API.Visibility;
  };
  pass?: NewPasswordPayload;
  action?: { op: 'merge' | 'overwrite' };
};

export const updateUserProfileTE = <E = Error>(
  { userId, body }: { userId?: string; body: UserBody },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Users.GetUserMeResponse> =>
  fetchTE<CF.API.Users.GetUserMeResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}`,
      method: 'PATCH',
      body,
    },
    onRejected,
  );
