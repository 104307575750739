import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const updateAppIdTE = <E = Error | CF.API.Apps.ListAppsResponse>(
  {
    userId,
    body,
  }: {
    userId: string;
    body: {
      ids: {
        id: string;
        new_id: string;
      }[];
      action: 'overwrite';
    };
  },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Apps.ListAppsResponse> =>
  fetchTE<CF.API.Apps.ListAppsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/ids`,
      method: 'PATCH',
      body,
    },
    onRejected,
  );
