import { KeyboardEvent, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { cx } from '@linaria/core';
import cogoToast from 'cogo-toast';
import nProgress from 'nprogress';
import { Form, Formik } from 'formik';
import { string, object } from 'yup';
import { createOrgTE } from 'api/organizations';
import { InputField } from 'components/Formik/InputField';
import { ButtonGroup } from 'components/deprecated/Button/ButtonGroup';
import { Button } from 'components/deprecated/Button';
import { IconCloseRound } from 'components/Icons';
import { useIdValidation$ } from 'hooks/useIdValidation$';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { errorToReactLeft, pipe } from 'utils/fp';
import { queryKeys, useMutationTE } from 'utils/react-query';
import { withAuth } from 'hocs/withAuth/withAuth';
import { cssHelpers } from 'styles/utils/core';
import { flex } from 'styles/utils';
import { createOrganizationWrapper, heading, closeIcon, formStyles } from './styles';
type CreateOrganizationFields = {
  orgId: string;
  orgName: string;
  billingEmail: string;
};
const stringRules = {
  generic: string().min(1, 'Too Short!').max(240, 'Too Long!').required('Required'),
  identifier: string().min(1, 'Too Short!').max(32, 'Too Long!').required('Required'),
  email: string().email().required('Required')
};
const CreateOrganization_ = ({
  closeAction,
  refetchList
}: {
  closeAction: () => void;
  refetchList: () => void;
}): JSX.Element => {
  const userId = useCurrentUserId();
  const queryClient = useQueryClient();
  const [onIdChange, {
    errorString
  }] = useIdValidation$({
    userId,
    entityName: 'user'
  });
  const createOrganizationMutation = useMutationTE((values: CreateOrganizationFields) => pipe(createOrgTE({
    organization: {
      id: values.orgId.trim(),
      name: values.orgName.trim(),
      billing_email: values.billingEmail.trim()
    }
  }, errorToReactLeft))(), {
    onMutate: () => {
      nProgress.start();
    },
    onSettled: () => {
      nProgress.done();
    },
    onSuccess: ({
      organizations: [createdOrg]
    }) => {
      cogoToast.success(`Organization "${createdOrg.name}" created.`);
      refetchList();
      closeAction();
      queryClient.invalidateQueries([queryKeys.Organizations]);
    },
    onError: ({
      props
    }) => {
      cogoToast.error(props?.reason || 'There was an error creating organization.', {
        heading: 'Error'
      });
      queryClient.invalidateQueries([queryKeys.Organizations]);
      closeAction();
    }
  });
  useEffect(() => {
    return () => {
      nProgress.done();
    };
  }, []);
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    event.stopPropagation();
  }, []);
  return <div className={createOrganizationWrapper}>
      <h1 className={heading}>Create an Organization</h1>
      <IconCloseRound size={24} className={closeIcon} onClick={closeAction} data-testid="close-create-organization" />
      <Formik enableReinitialize initialValues={{
      orgId: '',
      orgName: '',
      billingEmail: ''
    }} validationSchema={object().shape({
      billingEmail: stringRules.email,
      orgName: stringRules.generic,
      orgId: stringRules.identifier.test('org-ID', errorString, value => {
        if (value) {
          onIdChange(value);
          return !errorString;
        }
        return false;
      })
    })} onSubmit={(values: CreateOrganizationFields) => {
      createOrganizationMutation.mutate(values);
    }}>
        {({
        isSubmitting,
        isValid,
        dirty
      }) => <Form className={formStyles}>
            <InputField name="orgName" label="Organization Name" placeholder="Enter Unique Org. Name" truncateError required onKeyDown={handleKeyDown} />
            <InputField name="orgId" label="Organization ID" placeholder="Enter Unique Identifier" truncateError required onKeyDown={handleKeyDown} />
            <InputField name="billingEmail" label="Billing Email" placeholder="Enter your Billing Email" truncateError required onKeyDown={handleKeyDown} />

            <ButtonGroup className={cx(flex.init, flex.alignItems.center, flex.justifyContent.end)}>
              <Button className={cssHelpers.margin.x[1]} type="button" kind="outline" id="create-organization-cancel" data-testid="Cancel" onClick={closeAction}>
                Cancel
              </Button>
              <Button kind="primary" className="bold" id="create-organization-submit" type="submit" data-testid="Confirm" disabled={isSubmitting || !isValid || !dirty}>
                Confirm
              </Button>
            </ButtonGroup>
          </Form>}
      </Formik>
    </div>;
};
export const CreateOrganization = withAuth<{
  closeAction: () => void;
  refetchList: () => void;
}>({
  hideFor: 'GUEST'
})(CreateOrganization_);