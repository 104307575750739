import { either } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { observer } from 'mobx-react-lite';
import { Either } from 'fp-ts/lib/Either';
import { SupportedListingEntities } from 'modules/Listings/types';
import { ReactLeft, reactLeftToJSX, UI_STATES } from 'utils/uiStates/uiStates';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { AllListStoreTypes } from 'modules/Listings/store/StateContext';
import { List } from 'components/List';
import { wrapper } from './ListView.styled';
export interface State {
  initialise: () => void;
  load: () => void;
  list: Either<ReactLeft, SupportedListingEntities[]>;
  query: string;
  makeUrl: (a: Record<string, string>) => string;
  overrideUrlClick: (entitiy: SupportedListingEntities) => void;
  getIsEntityOwner: (b: Record<string, string>) => boolean;
}
interface ListViewProps {
  pageName: string;
  parentWidth?: number;
  showAppResourceCounts?: boolean;
}
interface ListViewControlledProps {
  state: AllListStoreTypes;
  pageName: string;
  parentWidth?: number;
  showAppResourceCounts?: boolean;
}
export const ListView: React.FC<ListViewProps> = observer(({
  pageName,
  parentWidth,
  showAppResourceCounts
}) => {
  const state = useCurrentStore();
  return <ListViewControlled pageName={pageName} state={state} parentWidth={parentWidth} showAppResourceCounts={showAppResourceCounts} />;
});
export const ListViewControlled: React.FC<ListViewControlledProps> = observer(({
  state,
  pageName,
  parentWidth,
  showAppResourceCounts
}) => {
  return pipe(state ? state.list : either.left({
    type: UI_STATES.empty
  }), either.fold(reactLeftToJSX(state?.getUiStateMap()), entityList => {
    return <div className={wrapper}>
          <List parentWidth={parentWidth} list={entityList} pageName={pageName} makeUrl={state.makeUrl} getIsEntityOwner={state.getIsEntityOwner} overrideUrlClick={'overrideUrlClick' in state ? state.overrideUrlClick : undefined} showAppResourceCounts={showAppResourceCounts} />
        </div>;
  }));
});
export const testable = {
  ListViewInternal: ListView
};