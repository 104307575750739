import { useModalActions } from 'components/ModalManager/Context';
import { useCallback, useEffect, useRef } from 'react';
import { noop } from 'utils/fp';
import { CreateApplicationModal } from 'modules/Apps/components/CreateApplication';
import { fullScreenModalStyle, height100proc } from 'modules/Apps/components/CreateApplication/CreateApplicationModal.styles';
import { useCurrentUserOrg } from 'modules/Organizations/hocs';
import { ORGANIZATION_ROLE_ID_MEMBER_MIN } from 'modules/Organizations/constants';
import { useAuthActions } from 'modules/Auth/AuthContext';
import { useCurrentUserId, useIsLoggedIn } from 'modules/Auth/hooks';
import { useRouter } from 'next/router';

const CREATE_APP_MODAL_ID = 'create_application_dialog';

/**
 * Single hook to open the create application modal. Useful for keeping the same modal ID
 * and to determine if user can create an app, which for team members in org is not allowed:
 * https://clarifai.atlassian.net/browse/MRK-2618
 */
export function useCreateApplicationModal({
  onAppCreated,
}: {
  onAppCreated: (createdApp: { userId: string; appId: string }, fromTemplate: boolean) => void;
}) {
  const router = useRouter();
  const { openModal, closeModal } = useModalActions();
  const { openLoginModal } = useAuthActions();
  const isTeamMember = useIsTeamMember();
  const openModalRef = useRef(false);
  const userId = useCurrentUserId();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (router.query.run_create_app) {
      if (isLoggedIn) {
        openCreateApplicationModal();
      } else {
        openLoginModal();
      }
    }
  }, [isLoggedIn]);

  const resetRouter = useCallback(() => {
    router.replace({ query: { ...router.query, run_create_app: [] } }, undefined, { shallow: true });
  }, [router]);

  const canCreateApplication = !isTeamMember;

  const openCreateApplicationModal = useCallback(() => {
    router.push({ query: { ...router.query, run_create_app: true } }, undefined, { shallow: true });
  }, [router]);

  const runOpenModal = useCallback(() => {
    if (canCreateApplication) {
      openModalRef.current = true;
      openModal({
        id: CREATE_APP_MODAL_ID,
        title: '',
        content: (
          <CreateApplicationModal
            closeAction={() => resetRouter()}
            onAppCreated={(createdApp: CF.API.Apps.App) => {
              resetRouter();
              onAppCreated({ appId: createdApp.id, userId: createdApp.user_id }, false);
            }}
            onAppDuplicated={(duplicatedApp: CF.API.Apps.DuplicatedApp) => {
              resetRouter();
              onAppCreated({ appId: duplicatedApp.new_app_id as string, userId }, true);
            }}
          />
        ),
        makeActions: noop,
        className: fullScreenModalStyle,
        contentWrapperClassName: height100proc,
        closeOnBackdropClick: false,
        hideOnEsc: false,
        persistOnRouteChange: true,
      });
    }
  }, [canCreateApplication, closeModal, onAppCreated, resetRouter]);

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      const parsedUrl = new URL(`${document.location.origin}${url}`);
      const runCreateFlag = parsedUrl.searchParams.get('run_create_app') === 'true';

      if (!openModalRef.current && !!runCreateFlag) {
        runOpenModal();
      } else if (openModalRef.current && !runCreateFlag) {
        closeModal({ id: CREATE_APP_MODAL_ID });
        openModalRef.current = false;
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [openCreateApplicationModal, closeModal]);

  return { canCreateApplication, openCreateApplicationModal };
}

function useIsTeamMember(): boolean {
  const userOrg = useCurrentUserOrg();
  return userOrg?.role.id === ORGANIZATION_ROLE_ID_MEMBER_MIN;
}
