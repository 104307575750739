import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { cx } from '@linaria/core';
import { noop } from 'utils/fp';
import { makeUserFullName } from 'utils/users';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { useAuthState, useAuthActions } from 'modules/Auth/AuthContext';
import { useModalActions } from 'components/ModalManager/Context';
import { CreateOrganization } from 'modules/Organizations/components/CreateOrganization';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { Button } from 'components/deprecated/Button';
import { cssHelpers } from 'styles/utils/core';
import { useFeaturedFlags } from 'modules/FeaturedFlags/useFeaturedFlags';
import { Account } from '../Account/Account';
import { accountSelector, accountDefault } from './styles';
const CREATE_ORGANIZATION_ID = 'create_organization_modal';
export const AccountSelector: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  ...rest
}): JSX.Element => {
  const router = useRouter();
  const currentUserId = useCurrentUserId();
  const {
    openModal,
    closeModal
  } = useModalActions();
  const {
    load
  } = useCurrentStore();
  const {
    authData
  } = useAuthState();
  const {
    changeUserAccountById
  } = useAuthActions();
  const [hasOrgAccess] = useFeaturedFlags(['OrgCreateOrganization']);
  const loggedInUserFullName = authData ? makeUserFullName(authData) : '';
  const orgsList = authData?.organizations?.map(({
    organization
  }) => organization) || [];
  const MAX_ORGS = 20;
  const isMaxOrgsReached = orgsList?.length >= MAX_ORGS;
  // Internal users can create multiple orgs, but external users can only create 1 org if not created yet
  const handleChangeUserId = useCallback(userOrOrgId => {
    const pathWithoutQueryParams = router.asPath?.split('?')[0] || '';
    const isCommunityRoute = pathWithoutQueryParams.startsWith('/explore');
    changeUserAccountById(userOrOrgId);

    // Redirect only when if user isn't viewing one of the explore pages:
    // https://clarifai.atlassian.net/browse/MRK-2721
    if (!isCommunityRoute) {
      router.push(`/${userOrOrgId}/apps`);
    }
  }, [changeUserAccountById, router]);
  return <div className={cx(accountSelector, className)} {...rest}>
      <Account type="user" name={loggedInUserFullName} selected={authData?.user_id === currentUserId} className={cx(!orgsList.length && accountDefault)} onSelect={() => handleChangeUserId(authData?.user_id)} id={authData?.user_id} />
      <>
        {orgsList.map(org => <Account key={org.id} type="org" name={org.name} id={org.id} selected={org.id === currentUserId} onSelect={() => handleChangeUserId(org.id)} />)}
        {hasOrgAccess && <Button kind="outline" className={cx('fullWidth', cssHelpers.margin.yb[1])} onClick={() => openModal({
        id: CREATE_ORGANIZATION_ID,
        title: '',
        content: <CreateOrganization closeAction={() => closeModal({
          id: CREATE_ORGANIZATION_ID
        })} refetchList={load} />,
        makeActions: noop
      })} disabled={isMaxOrgsReached}>
            + Add an Organization
          </Button>}
      </>
    </div>;
};