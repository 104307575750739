/**
 * https://github.com/segmentio/is-url/blob/master/index.js
 */

import { blobToBase64 } from './general';

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

const localhostDomainRE = /^localhost[\d:?]*(?:[^\d:?]\S*)?$/;
const nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}$/;

export function isUrl(string: string): boolean {
  const match = string.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }
  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol)) {
    return true;
  }

  return false;
}

export const fetchAsBase64 = async (url: string): Promise<{ base64: string | ArrayBuffer | null; contentType: string }> => {
  const data = await fetch(url);
  if (!data.ok) {
    throw new Error(`HTTP error! Status: ${data.status}`);
  }
  const blob = await data.blob();
  const base64 = await blobToBase64(blob);
  return {
    base64,
    contentType: blob.type,
  };
};

export const getExampleURLs = (inputType: CF.API.InputType): string => {
  switch (inputType) {
    case 'audio':
      return 'https://samples.clarifai.com/GoodMorning.wav, https://samples.clarifai.com/whatstheweatherlike.wav';
    case 'text':
      return 'https://samples.clarifai.com/model-gallery/Text/neural-network-en.txt, https://samples.clarifai.com/model-gallery/Text/artificial-intelligence-en.txt';
    case 'video':
      return 'https://samples.clarifai.com/small.mp4, https://samples.clarifai.com/demo-vid-1.mp4';
    default:
      return 'https://samples.clarifai.com/cat1.jpeg, https://samples.clarifai.com/dog2.jpeg';
  }
};

export const getBaseApiUrl = (apiHost: string): string => {
  const url = new URL(apiHost);

  return url.origin;
};

export function appendCachebusterParam(url: string): string {
  if (!url || url.startsWith('data:')) {
    return url;
  }
  const stamp = `t=${Date.now()}`;
  return url.includes('?') ? `${url}&${stamp}` : `${url}?${stamp}`;
}
