import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const createUserInviteTE = <E = Error>(
  { orgId, memberEmail, memberRoleId }: { orgId: string; memberEmail: string; memberRoleId: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Organizations.ListInvitedOrgMembersResponse> =>
  fetchTE<CF.API.Organizations.ListInvitedOrgMembersResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/invitations`,
      method: 'POST',
      body: {
        invitations: [
          {
            invitee_email: memberEmail,
            role: {
              id: memberRoleId,
            },
          },
        ],
      },
    },
    onRejected,
  );
