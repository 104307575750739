import { tagItem, tagWrapper } from './TagList.styles';
import { EntityCardLayoutProps } from './types';

export const TagList: React.FC<{ tags?: EntityCardLayoutProps['tags'] }> = ({ tags }) => {
  if (!tags?.length) {
    return null;
  }

  return (
    <div className={tagWrapper}>
      {tags.map(({ uniqueKey, item }) => {
        return (
          <span className={tagItem} key={uniqueKey}>
            {item}
          </span>
        );
      })}
    </div>
  );
};
