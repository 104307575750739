import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const getInputAddJobTE = <E = Error>(
  { userId, appId, jobId }: { userId: string; appId: string; jobId: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Inputs.InputAddJobResponse> =>
  fetchTE<CF.API.Inputs.InputAddJobResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/inputs/jobs/add/${jobId}`,
      method: 'GET',
    },
    onRejected,
  );
