import { css, cx } from '@linaria/core';
import { flex, theming, breakpoints } from 'styles/utils';
import { HEADER_HEIGHT } from 'styles/constants';
import { ellipsis } from 'polished';
export const nav = "omponents__Navbar__styles_ts-nav-n1t3t01";
export const navContent = cx("omponents__Navbar__styles_ts-navContent-nffy06m", flex.init, flex.alignItems.center, flex.gap[1]);
export const hideBelow900 = "omponents__Navbar__styles_ts-hideBelow900-hzwd7am";
export const hideAbove900 = "omponents__Navbar__styles_ts-hideAbove900-h1ph6zrq";
const _baseImage = "omponents__Navbar__styles_ts-_baseImage-_1egui1s";
const _logo = "omponents__Navbar__styles_ts-_logo-_1qucpup";
export const logo = cx(_baseImage, _logo);
const _profile = "omponents__Navbar__styles_ts-_profile-_z2kg35";
export const _baseIcon = "omponents__Navbar__styles_ts-_baseIcon-_24kall";
export const profile = cx("omponents__Navbar__styles_ts-profile-p1xtji57", _baseImage, _profile, _baseIcon);
export const navLink__Active = "omponents__Navbar__styles_ts-navLink__Active-nh0tj78";
export const navLink__Radius = "omponents__Navbar__styles_ts-navLink__Radius-n31458e";
export const navLink__Text = "omponents__Navbar__styles_ts-navLink__Text-n2u2kfw";
export const buttonIcon = "omponents__Navbar__styles_ts-buttonIcon-bc2hdyy";
export const navAuthWrapper = cx("omponents__Navbar__styles_ts-navAuthWrapper-n94jbp9", flex.init, flex.alignItems.center, flex.justifyContent.end, flex.flex.one, flex.gap[1]);
export const navBarDropdown = "omponents__Navbar__styles_ts-navBarDropdown-ntbz94m";
export const mobileNavbarDropdown = cx("omponents__Navbar__styles_ts-mobileNavbarDropdown-mdmhkq4", hideAbove900);
export const createAppBtn = "omponents__Navbar__styles_ts-createAppBtn-c1ybeagf";
export const accountPopover = cx("omponents__Navbar__styles_ts-accountPopover-a1p5bgs7", hideBelow900);
export const btnNoBorder = "omponents__Navbar__styles_ts-btnNoBorder-ba067pg";
export const switchToLegacyBtn = cx("omponents__Navbar__styles_ts-switchToLegacyBtn-s1d72wk5", btnNoBorder);
export const iconsTray = cx("omponents__Navbar__styles_ts-iconsTray-idcor00", flex.init, flex.alignItems.center);
export const pointer = "omponents__Navbar__styles_ts-pointer-ph70x1v";

require("./styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./styles.ts");