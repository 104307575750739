import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const listPublicWorkflowsTE = <E = Error>(
  { userId, params = '' }: { userId?: string; params?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Workflows.ListWorkflowsRes> =>
  fetchTE<CF.API.Workflows.ListWorkflowsRes, E>(
    {
      path: `/api/_api/v2/users/${userId}/public_workflows${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );
