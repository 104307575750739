import { defaultIconProps, IconProps } from './IconProps';

export const CalendarIcon: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12.25 5.83335H1.75M9.33333 1.16669V3.50002M4.66667 1.16669V3.50002M4.55 12.8334H9.45C10.4301 12.8334 10.9201 12.8334 11.2945 12.6426C11.6238 12.4748 11.8915 12.2071 12.0593 11.8778C12.25 11.5035 12.25 11.0134 12.25 10.0334V5.13335C12.25 4.15326 12.25 3.66322 12.0593 3.28887C11.8915 2.95959 11.6238 2.69187 11.2945 2.52409C10.9201 2.33335 10.4301 2.33335 9.45 2.33335H4.55C3.56991 2.33335 3.07986 2.33335 2.70552 2.52409C2.37623 2.69187 2.10852 2.95959 1.94074 3.28887C1.75 3.66322 1.75 4.15326 1.75 5.13335V10.0334C1.75 11.0134 1.75 11.5035 1.94074 11.8778C2.10852 12.2071 2.37623 12.4748 2.70552 12.6426C3.07986 12.8334 3.56991 12.8334 4.55 12.8334Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CalendarIcon.defaultProps = defaultIconProps;
