import { useCallback, useEffect, useState } from 'react';
import { either } from 'fp-ts';
import cogoToast from 'cogo-toast';
import { GRPCClient } from 'api/grpc/client';
import { Input } from 'clarifai-web-grpc/proto/clarifai/api/resources_pb';
import { StatusCode } from 'clarifai-web-grpc/proto/clarifai/api/status/status_code_pb';
import { MultiInputResponse } from 'api/grpc/service';
import { InputsManagerStore } from 'modules/InputsManager/store';
import responseCodes from 'server/responseCodes';
import { makeUID } from 'utils/strings/strings';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { listInputAddJobsTE } from 'api/inputJobs/listInputAddJobs';
import { errorToReactLeft, noop } from 'utils/fp';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { getFileChunks, initializeUploadJob, uploadChunk } from 'utils/uploadHelpers';
import { useBaseReqParams } from './useBaseReqParams';

export const UPLOAD_BUCKET_SIZE = 10;
export const CSV_UPLOAD_BUCKET_SIZE = 128;
const MAX_FILE_SIZE = 10000000; // 10MB

// https://stackoverflow.com/questions/19376136/amazon-s3-your-proposed-upload-is-smaller-than-the-minimum-allowed-size/19378542#19378542
export const FILE_CHUNK_SIZE = 6000000; // each chunk should be more than minimum allowed size 5mb.

export type OnInitUploadHandler = (jobId: UploadJobType['jobId'], inputs: Input[]) => void;
export type UploadHandler = (
  client: GRPCClient,
  userOrOrgId: string,
  appId: string,
  inputs: Input[],
  files: File[],
  onInitUpload?: OnInitUploadHandler,
  inputType?: string,
) => void;
export type UploadArchiveHandler = (client: GRPCClient, file: File, patKey: string) => Promise<void>;
export type UpdateUploadJobHandler = (jobId: string, update: Partial<UploadJobType>) => void;

export type UploadJobType = {
  jobId: string;
  userOrOrgId: string;
  appId: string;
  createdAt: Date | string;
  inputsCount: number;
  uploadedCount: number;
  successCount: number;
  failedCount: number;
  finished: boolean;
  percentage: number;
  error?: Error | null;
  inputsSelected?: number;
  store?: InputsManagerStore;
};

type UseUploader = () => {
  jobs: Record<string, UploadJobType>;
  upload: UploadHandler;
  updateUploadJob: UpdateUploadJobHandler;
  uploadArchive: UploadArchiveHandler;
};

type InitJobType = {
  jobId: string;
  userOrOrgId: string;
  appId: string;
} & Partial<UploadJobType>;

export const useUploader: UseUploader = () => {
  const [uploadJobs, setUploadJobs] = useState<Record<string, UploadJobType>>({});
  const [activeJobInputCount, setActiveJobInputCount] = useState<Record<string, number>>({});
  const { userOrOrgId: currentUserId, appId: currentAppId } = useBaseReqParams();
  const { data } = useQueryTE(
    [queryKeys.ListInputAddJobs, { userId: currentUserId, appId: currentAppId }],
    listInputAddJobsTE({ userId: currentUserId, appId: currentAppId }, errorToReactLeft),
    { enabled: Boolean(currentUserId && currentAppId) },
  );

  useEffect(() => {
    either.fold<ReactLeft, CF.API.Inputs.ListInputAddJobsResponse, void>(
      () => noop,
      (r: CF.API.Inputs.ListInputAddJobsResponse): void => {
        let newJobs: typeof uploadJobs = {};
        r.inputs_add_jobs?.forEach((job: CF.API.Inputs.InputsAddJobsEntity) => {
          const inputsCount = Number(job.progress.success_count || 0) + Number(job.progress.in_progress_count || 0);
          const successCount = Number(job.progress.success_count || 0);
          const failedArchivesCount = Number(job?.extraction_jobs?.[0]?.progress?.failed_archives_count ?? 0);

          const newJob: UploadJobType = {
            jobId: job.id,
            userOrOrgId: currentUserId as string,
            appId: currentAppId as string,
            // activeJobInputCount is used to show the correct number of active inputs in the progress bar
            // if the job is not active, we use the inputsCount from the API
            // if the job is active, we use the activeJobInputCount from the state
            // This is important as when we upload multiple jobs at the same time, the inputsCount from the API
            // coming for active inputs will not reflect the actual inputsCount as it is a combination of (success_count + in_progress_count)
            inputsCount: activeJobInputCount[job.id] || inputsCount,
            successCount,
            failedCount: failedArchivesCount,
            uploadedCount: inputsCount,
            finished: !job.progress.in_progress_count,
            createdAt: job.created_at,
            percentage: uploadJobs[job.id]?.percentage || Number((100 * (inputsCount + successCount)) / (inputsCount * 2)),
          };
          if (inputsCount > 0) {
            newJobs = {
              ...newJobs,
              [newJob.jobId]: newJob,
            };
          }
        });

        setUploadJobs((currentJobs) => ({
          ...currentJobs,
          ...newJobs,
        }));
      },
    )(data);
  }, [data, currentUserId, currentAppId]);

  const updateUploadJob = useCallback(
    (jobId: string, update: Partial<UploadJobType>) => {
      setUploadJobs((oldJobs) => ({
        ...oldJobs,
        [jobId]: {
          ...oldJobs[jobId],
          ...update,
        },
      }));
    },
    [setUploadJobs],
  );

  // returns the number of buckets needed to upload the files
  const getUploadBucketSize = (files: File[]) => {
    let sum = 0;
    let bucketSize = 0;

    if (files.length > 0) {
      for (const file of files) {
        // Check if file is greater than given size
        if (file.size > MAX_FILE_SIZE) return bucketSize + 1;

        // Check if adding file will exceed given size
        if (sum + file.size >= MAX_FILE_SIZE) return bucketSize;

        sum += file.size;
        bucketSize += 1;
      }
    }
    return bucketSize;
  };

  const createUploadJob = useCallback(
    (job: InitJobType): void => {
      setUploadJobs((oldJobs) => ({
        ...oldJobs,
        [job.jobId]: {
          createdAt: new Date(),
          inputsCount: 0,
          uploadedCount: 0,
          successCount: 0,
          failedCount: 0,
          percentage: 0,
          finished: false,
          error: null,
          ...job,
        },
      }));
    },
    [setUploadJobs],
  );

  /** Returns promise when job is submitted */

  type GetResponseCall = {
    client: GRPCClient;
    userOrOrgId: string;
    appId: string;
    jobId: string;
    inputs: Input[];
  };

  const getUploadResponse = async ({
    client,
    userOrOrgId,
    appId,
    jobId,
    inputs,
  }: GetResponseCall): Promise<string | MultiInputResponse | undefined> => {
    let resp;
    try {
      resp = await client.postInputs({ userOrOrgId, appId, jobId, inputs });
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      if (either.isLeft(resp)) throw resp;
    } catch {
      return 'Error';
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return resp ? (either.getOrElse(() => undefined)(resp) as unknown as MultiInputResponse) : undefined;
  };

  const getFailedInputsCount = (resp: MultiInputResponse): number => {
    let failedInputsCount: number = 0;
    const uploadedInputs = resp.getInputsList();
    uploadedInputs.forEach((input: Input) => {
      // checking if the input failed
      const statusCode = input.getStatus()?.getCode() || undefined;
      if (statusCode === StatusCode.INPUT_UNSUPPORTED_FORMAT) {
        // input failed
        failedInputsCount += 1;
      }
    });
    return failedInputsCount;
  };

  const upload = useCallback(
    (client, userOrOrgId, appId, inputs, files, onInitUpload, inputType?) => {
      // creating async function to be able to use await inside
      async function uploadAsync(): Promise<void> {
        const jobId = makeUID(16);
        createUploadJob({ jobId, userOrOrgId, appId });
        const newInputsArr = inputs.flat();
        setActiveJobInputCount((prev) => ({ ...prev, [jobId]: newInputsArr.length }));

        // setting new ids for the inputs
        newInputsArr.forEach((input: Input) => {
          const newId = makeUID(16);
          input.setId(newId);
        });

        // using correct inputs count for the job
        updateUploadJob(jobId, { inputsCount: newInputsArr.length });

        // Callback aimed to be triggered before actually starting the upload process.
        onInitUpload?.(jobId, newInputsArr);

        let inputsToUpload: Input[] = [];
        // getting the first bucket of inputs to upload
        if (!files) {
          const bucketSize = getUploadBucketSize(files);
          inputsToUpload = inputs.splice(0, bucketSize);
        } else if (inputType === 'text/csv') {
          inputsToUpload = inputs.splice(0, CSV_UPLOAD_BUCKET_SIZE);
        } else {
          inputsToUpload = inputs.splice(0, UPLOAD_BUCKET_SIZE);
        }

        while (inputsToUpload.length) {
          let failedInputsCount: number = 0;
          const uploadedCount = inputsToUpload.length;
          const resp = await getUploadResponse({ client, userOrOrgId, appId, jobId, inputs: inputsToUpload });

          if (resp && typeof resp !== 'string') {
            const responseStatus = (resp as MultiInputResponse).getStatus();
            const responseStatusCode = responseStatus?.getCode();
            // some inputs failed
            if (responseStatusCode === responseCodes.MIXED_SUCCESS.code) {
              const newFailedInputsCount = getFailedInputsCount(resp);
              failedInputsCount += newFailedInputsCount;
            }
          } else {
            // all inputs failed
            failedInputsCount += uploadedCount;
          }

          // updating the job with the new counts
          setUploadJobs((oldJobs) => {
            const totalUploaded = oldJobs[jobId].uploadedCount + uploadedCount;
            return {
              ...oldJobs,
              [jobId]: {
                ...oldJobs[jobId],
                uploadedCount: totalUploaded,
                failedCount: oldJobs[jobId].failedCount + failedInputsCount,
              },
            };
          });
          // getting new bucket of inputs to upload
          if (!files) {
            files.splice(0, inputsToUpload.length);
            const newBucketSize = getUploadBucketSize(files);
            inputsToUpload = inputs.splice(0, newBucketSize);
          } else if (inputType === 'text/csv') {
            inputsToUpload = inputs.splice(0, CSV_UPLOAD_BUCKET_SIZE);
          } else {
            inputsToUpload = inputs.splice(0, UPLOAD_BUCKET_SIZE);
          }
        } // end of while loop
      }
      uploadAsync();
    },
    [setUploadJobs],
  );

  const uploadArchive = useCallback(async (client, file, patKey) => {
    const { jobId, uploadId } = await initializeUploadJob({ client, userOrOrgId: currentUserId, appId: currentAppId, file, patKey });

    if (!uploadId) {
      cogoToast.error('Error while uploading file...');
      return;
    }

    createUploadJob({ jobId, userOrOrgId: currentUserId, appId: currentAppId, inputsCount: 1 });

    const chunks = await getFileChunks(file, FILE_CHUNK_SIZE);

    for (const { chunk, partNumber, start } of chunks) {
      const percentage = await uploadChunk({ client, userOrOrgId: currentUserId, appId: currentAppId, uploadId, chunk, partNumber, start });
      if (percentage !== null) {
        updateUploadJob(jobId, {
          uploadedCount: percentage === 100 ? 1 : 0,
          percentage,
        });
      } else {
        // percentage 100, because failed job is already finished uploading
        updateUploadJob(jobId, { failedCount: 1, finished: true, uploadedCount: 1, percentage: 100 });
        break;
      }
    }
  }, []);

  return {
    upload,
    updateUploadJob,
    jobs: uploadJobs,
    uploadArchive,
  };
};
