import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';
import type { SSOMethodsResponse } from 'api/users/sso';

export const fetchAuthMethodsTE = <E = Error>(
  { orgId, params }: { orgId: string; params?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, SSOMethodsResponse> =>
  fetchTE<SSOMethodsResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/auth/methods${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );
