import { Row, Col } from 'components/Grid';
import { EntityCard } from 'components/EntityCard';
import { FeaturedEntityCard } from 'modules/Featured/components/FeaturedEntityCard';
import {
  getEntityId,
  getAppId,
  getUserId,
  getEntityTitle,
  getEntityDescription,
  getFooterItems,
  getEntityVisibility,
  getEntityName,
} from 'modules/Listings/helpers/entityCardUtils';
import { SupportedListingEntities } from 'modules/Listings/types';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { useCurrentStore } from 'modules/Listings/store/useCurrentStore';
import { SocialShare } from 'components/SocialShare';
import { StarComponent } from './components/Star/Star';
import { ScrollTrigger } from './ScrollTrigger';
import { LockComponent } from './components/Lock/Lock';

const communityPages = ['/explore', '/explore/models', '/explore/workflows', '/explore/modules'];

const List_: React.FC<{
  list: SupportedListingEntities[];
  makeUrl: (a: Record<string, string>) => string;
  pageName: string;
  parentWidth?: number;
  overrideUrlClick?: (entity: SupportedListingEntities) => void;
  getIsEntityOwner: (b: Record<string, string>) => boolean;
  showAppResourceCounts?: boolean;
  showFeatured?: boolean;
}> = ({
  list,
  makeUrl,
  getIsEntityOwner,
  overrideUrlClick,
  pageName,
  showAppResourceCounts,
  parentWidth = 1000,
  showFeatured = false,
}): JSX.Element => {
  const store = useCurrentStore();
  const router = useRouter();
  const isCommunityPage = communityPages.includes(router.pathname);

  return (
    <>
      <Row paddingX="10px" rowSpacing="1rem" spacing="1rem" data-testid={`list-${pageName}`}>
        {list.map((entity) => {
          const entityParams = {
            appId: getAppId(entity),
            userId: getUserId(entity),
            entityId: getEntityId(entity),
            entityTitle: getEntityTitle(entity),
          };

          const editableByOwner = getIsEntityOwner(entityParams);
          const href = makeUrl(entityParams);
          const items = getFooterItems(entity);
          const StarComp = 'is_starred' in entity ? <StarComponent entity={entity} /> : null;
          const isPublic = getEntityVisibility(entity) === 'PUBLIC';
          const ShareComp = isPublic ? <SocialShare pathname={href} /> : null;
          const entityName = router.pathname.includes('/datasets') ? 'datasets' : getEntityName(entity);
          const LockComp = !isPublic ? <LockComponent tooltipText={`This ${entityName} is private and not shared in Community`} /> : null;

          const isGridLayout = store.viewMode === 'grid';
          const xsColCount = isGridLayout ? 12 : 12;
          const smColCount = isGridLayout ? 6 : 12;
          const mdColCount = isGridLayout ? 4 : 12;
          const lgColCount = isGridLayout ? 4 : 6;
          const xlColCount = isGridLayout ? 3 : 6;

          if (overrideUrlClick) {
            return (
              <Col xs={xsColCount} sm={smColCount} md={mdColCount} lg={lgColCount} xl={xlColCount} key={entityParams.entityTitle}>
                {showFeatured ? (
                  <FeaturedEntityCard key={entityParams.entityId} onClick={() => overrideUrlClick(entity)} entity={entity} />
                ) : (
                  <EntityCard
                    key={entityParams.entityId}
                    parentWidth={parentWidth}
                    viewMode={store.viewMode}
                    onClick={() => overrideUrlClick(entity)}
                    pageName={pageName}
                    title={entityParams.entityTitle}
                    description={getEntityDescription(entity)}
                    footerItems={items}
                    share={ShareComp}
                    star={StarComp}
                    owner={!isCommunityPage && editableByOwner}
                    entity={entity}
                    lock={LockComp}
                    showAppResourceCounts={showAppResourceCounts}
                  />
                )}
              </Col>
            );
          }
          return (
            <Col xs={xsColCount} sm={smColCount} md={mdColCount} lg={lgColCount} xl={xlColCount} key={entityParams.entityTitle}>
              {showFeatured ? (
                <FeaturedEntityCard key={entityParams.entityId} href={href} entity={entity} />
              ) : (
                <EntityCard
                  key={entityParams.entityId}
                  viewMode={store.viewMode}
                  parentWidth={parentWidth}
                  href={href}
                  pageName={pageName}
                  title={entityParams.entityTitle}
                  description={getEntityDescription(entity)}
                  footerItems={items}
                  share={ShareComp}
                  star={StarComp}
                  lock={LockComp}
                  owner={!isCommunityPage && editableByOwner}
                  entity={entity}
                  showAppResourceCounts={showAppResourceCounts}
                />
              )}
            </Col>
          );
        })}
      </Row>
      <ScrollTrigger store={store} />
    </>
  );
};

export const List = observer(List_);
