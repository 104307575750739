import { compose, propOr, join, map, identity, split } from 'rambda';

export const makeLocalUserUniqueId = (user: CF.LocalUser): string => user.user_id;

export const makeUserFullName = (user: CF.API.Users.User | CF.LocalUserWithOrg): string =>
  `${propOr('', 'first_name')(user)} ${propOr('', 'last_name')(user)}`.trim();

export const makeUserInitials = (user: CF.API.Users.User | CF.LocalUserWithOrg): string =>
  compose(join(''), map(identity((val) => val.charAt(0))), split(' '))(makeUserFullName(user));

export const getUserPrimaryEmail = (user: CF.API.Users.User): CF.API.Users.EmailAddressesEntity => {
  return user.email_addresses?.find((email) => email.primary) as CF.API.Users.EmailAddressesEntity;
};

export const isUserVerified = (user: CF.API.Users.User): boolean => {
  return user.email_addresses?.find((email) => email.primary)?.verified || false;
};

export const isOnboardingPending = (user: CF.API.Users.User): boolean => {
  const requiredUserInfo = ['id', 'first_name', 'last_name', 'company_name', 'job_role', 'job_title', 'date_gdpr_consent', 'date_tos_consent'];
  return isUserVerified(user) && !requiredUserInfo.every((key) => Object.keys(user).includes(key));
};
