import { useCallback } from 'react';
import { either, taskEither } from 'fp-ts';
import { duplicateApp, listAppsTE } from 'api/apps';
import { useCurrentUserId } from 'modules/Auth/hooks';
import type { SelectOption } from 'components/Select';
import { errorToReactLeft, pipe } from 'utils/fp';
import { useQueryTE } from 'utils/react-query';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { queryKeys } from 'utils/react-query/queryKeys';
import { useAuthState } from 'modules/Auth/AuthContext';
import { makeUserFullName } from 'utils/users';

export function useDuplicateApplicationTE({ userId, appId }: { userId: string; appId: string }): (values: {
  selectedUser: SelectOption;
  selectedDestination?: SelectOption;
  existing_app_id?: string;
  new_app_id?: string;
  new_app_name?: string;
  destination_app?: {
    description?: string;
    id?: string;
  };
  filters?: {
    [key: string]: boolean;
  };
}) => taskEither.TaskEither<ReactLeft, CF.API.Apps.duplicateMockApp> {
  return useCallback(
    (values) => {
      const payload: {
        new_app_id?: string;
        new_app_name?: string;
        existing_app_id?: string;
        destination_app?: {
          description?: string;
          id?: string;
        };
        filter?: {
          [key: string]: boolean;
        };
      } = {
        ...(values.selectedDestination?.value !== 'new_app' && { existing_app_id: values.selectedDestination?.value }),
      };
      if (values.new_app_id) {
        payload.new_app_id = values.new_app_id;
      }
      if (values.new_app_name) {
        payload.new_app_name = values.new_app_name;
      }
      if (values.filters) {
        payload.filter = values.filters;
      }

      if (values.destination_app) {
        payload.destination_app = values.destination_app;
      }

      return pipe(
        duplicateApp(
          {
            userId,
            appId,
            body: {
              app_duplications: [
                {
                  ...payload,
                },
              ],
            },
          },
          errorToReactLeft,
        ),
      );
    },
    [], // Add an empty dependency list here
  );
}

export function useAppOptions(userId: string, excludeCurrentAppId: string) {
  const { data } = useQueryTE([queryKeys.Apps, { userOrOrgId: userId }], listAppsTE({ userId }, errorToReactLeft), {
    enabled: Boolean(userId),
  });

  const transformForList = (app: CF.API.Apps.App): SelectOption => ({ value: app.id, name: app.id });

  return pipe(
    data,
    /* istanbul ignore next */
    either.map(({ apps }) => apps.map(transformForList)),
    either.map((apps) => apps.filter((app) => app.value !== excludeCurrentAppId)),
  );
}

export function useUserAndOrgsOptions(): [SelectOption, SelectOption[]] {
  const { authData } = useAuthState();
  const currentUserId = useCurrentUserId();

  const loggedInUser = { value: authData?.user_id || '', name: authData ? `${makeUserFullName(authData)} (you)` : '' };
  const orgsList =
    authData?.organizations?.map(({ organization }) => ({
      value: organization.id,
      name: organization.name,
    })) || [];

  const userAndOrgsList = (loggedInUser ? [loggedInUser].concat(orgsList) : []) as SelectOption[];
  const defaultSelectedUserOption = userAndOrgsList.find((option) => option.value === currentUserId) as SelectOption;

  return [defaultSelectedUserOption, userAndOrgsList];
}
