import React from 'react';
import { badge, badgeWrapper } from './NotificationBadge.styles';

interface NotificationBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  count?: number;
  'data-testid'?: string;
}

export const NotificationBadge: React.FC<NotificationBadgeProps> = ({ count, children, ...props }) => {
  return (
    <div className={badgeWrapper} {...props}>
      {children}
      {count ? (
        <div data-testid={`${props['data-testid']}-count`} className={badge}>
          {count}
        </div>
      ) : null}
    </div>
  );
};
