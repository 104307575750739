import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const deleteUserInviteTE = <E = Error>(
  { orgId, invitationId, cancelled_at }: { orgId: string; invitationId: string; cancelled_at: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Organizations.DeleteUserInviteResponse> =>
  fetchTE<CF.API.Organizations.DeleteUserInviteResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/invitations`,
      method: 'PATCH',
      body: {
        invitations: [
          {
            id: invitationId,
            cancelled_at,
          },
        ],
      },
    },
    onRejected,
  );
