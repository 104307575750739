import { Link } from 'components/Link';
import { SSOButtonProps } from 'modules/Auth/components/types';
import { ssoButton } from 'modules/Auth/components/styles';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuthActions } from 'modules/Auth/AuthContext';

export const SSOButton = ({ auth_link, name, icon, is_signup }: SSOButtonProps): JSX.Element => {
  const router = useRouter();
  const { closeLoginModal } = useAuthActions();
  const { track } = useDataCollection();

  const onSSOClick = (): void => {
    track(`SSO ${name} Clicked`, { pageName: 'login_page', name });
  };

  useEffect(() => {
    const handler = (e: MessageEvent) => {
      if (e.data.type === 'login') {
        closeLoginModal();
        router.reload();
      }
    };
    window.addEventListener('message', handler, false);
    return () => {
      window.removeEventListener('message', handler, false);
    };
  }, []);

  return (
    <Link
      onClick={(e) => {
        e.preventDefault();
        const newWindowWidth = 800;
        const newWindowHeight = 600;
        const screenWidth = window.innerWidth
          ? window.innerWidth
          : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : window.screen.width;
        const screenHeight = window.innerHeight
          ? window.innerHeight
          : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : window.screen.height;

        const left = (screenWidth - newWindowWidth) / 2;
        const top = (screenHeight - newWindowHeight) / 2;

        window.open(auth_link, 'pagename', `top=${top},left=${left},popup,resizable,height=${newWindowHeight},width=${newWindowWidth}`);
        onSSOClick();
      }}
      href={auth_link}
      className={ssoButton}
      data-testid={`sso-provider-${name}`}
    >
      {name === 'github' && <img className="icon" src="/sso_icons/github-new.svg" alt="github-icon" />}
      {name === 'okta' && <img className="icon" src="/sso_icons/okta-new.svg" alt="okta-icon" />}
      {name !== 'github' && name !== 'okta' && <img className="icon" src={icon} alt={`${name}-icon`} />}

      <div className="text-wrapper">
        <span className="sso-provider-text">{is_signup ? 'Sign up with ' : 'Log in with '}</span>
        <span className="sso-provider">{name}</span>
      </div>
    </Link>
  );
};
