import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE, fetchApi } from 'utils/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const listAppsTE = <E = Error>(
  { userId, params = '' }: { userId?: string; params?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Apps.ListAppsResponse> =>
  fetchTE<CF.API.Apps.ListAppsResponse, E>(
    {
      path: userId ? `/api/_api/v2/users/${userId}/apps${params ? `?${params}` : ''}` : `/api/_api/v2/apps${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );

export const apiListApps = ({
  userId,
  params = '',
  sessionToken,
}: {
  userId?: string;
  params?: string;
  sessionToken?: string;
}): Promise<CF.API.Apps.ListAppsResponse> =>
  fetchApi({
    path: userId ? `/api/_api/v2/users/${userId}/apps${params ? `?${params}` : ''}` : `/api/_api/v2/apps${params ? `?${params}` : ''}`,
    sessionToken,
    method: 'GET',
  });
