import { MenuInitialState, useMenuState } from 'reakit';
import { ControlledSelectProps, SelectControlled } from 'components/Select/SelectControlled';
import { Field, FieldProps, FieldConfig } from 'formik';
import { baseTheme } from 'styles/utils/core';
import React, { useCallback } from 'react';
import { cx } from '@linaria/core';
import { fieldWrapper, formLabel } from './Forms.styles';
import { FormGroup } from './FormGroup';
import { LabelWithTooltip, TooltipText } from './LabelWithTooltip';

export type SelectFieldProps<ItemExtras> = {
  label?: string;
  tooltip?: {
    title: string;
    text: TooltipText;
  };
  required?: boolean;
  title?: string | null;
  formGroupClassName?: string;
  menuState?: MenuInitialState;
  onChange?: (v: { value: string; name: string }) => void;
  menuWrapperClass?: string;
} & Omit<ControlledSelectProps<ItemExtras>, 'menuStateReturn' | 'title' | 'onChange'>;

// eslint-disable-next-line @typescript-eslint/ban-types
function SelectField_<ItemExtras extends object>({
  options,
  placeholder,
  menuState,

  tooltip,
  label,
  required,

  title,

  formGroupClassName,
  selectElementClassName,

  className,
  id,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  form, // form is unused prop but needs to be picked so as to not be spreaded down
  field,
  meta,
  onChange,
  menuWrapperClass,
  ...restInputProps
}: SelectFieldProps<ItemExtras> & FieldProps): React.ReactElement {
  const menuStateReturn = useMenuState({ animated: 150, placement: 'bottom-start', ...menuState });
  const error = meta.error;
  const isErrored = meta.touched && error;
  const idForLabel = id || label || field.name; // fallback to other options to always ensure having a valid htmlFor id for the label
  const labelText = `${label}${required ? ' *' : ''}`;

  return (
    <FormGroup className={formGroupClassName}>
      <div className={cx(fieldWrapper, isErrored && 'invalid', className)}>
        {Boolean(label) && (
          <LabelWithTooltip className={formLabel} htmlFor={idForLabel} tooltipTitle={tooltip?.title} tooltipText={tooltip?.text}>
            {labelText}
          </LabelWithTooltip>
        )}
        <SelectControlled<ItemExtras>
          modal
          menuStateReturn={menuStateReturn}
          title={
            title ||
            (field.value !== null && typeof field.value === 'object' && 'name' in field.value ? String(field.value.name) : placeholder || field.name)
          }
          {...restInputProps}
          onChange={(selectOption) => {
            if (onChange) {
              onChange(selectOption);
            } else {
              form.setFieldValue(field.name, selectOption);
            }
          }}
          options={options}
          selectElementClassName={selectElementClassName}
          menuWrapperClass={menuWrapperClass}
        />
        {isErrored && (
          <p
            style={{
              color: baseTheme.light.colors.red,
              fontSize: '10px',
              position: 'absolute',
              display: 'block',
            }}
          >
            {meta.error}
          </p>
        )}
      </div>
    </FormGroup>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function SelectField<ItemExtras extends object>(
  props: SelectFieldProps<ItemExtras> & Omit<FieldConfig, 'component' | 'children'>,
): JSX.Element {
  /* eslint-disable react/no-children-prop */
  // avoid field rerendering on parent rerender
  const children = useCallback((fieldProps: FieldProps) => <SelectField_ {...fieldProps} {...props} />, [props]);

  return <Field {...props} children={children} />;
}
