import { baseTheme } from 'styles/utils';
import { IconProps } from './IconProps';

interface IconUploadProps extends IconProps {
  size: number;
  color?: string;
}

export const IconThinPlus: import('react').FC<IconUploadProps> = ({ size = 20, color = baseTheme.light.colors.gray700, ...rest }) => (
  <svg {...rest} width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4.16666V15.8333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.1665 10H15.8332" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
