import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE, ErrorResponse } from 'utils/request';

export const postModelVersionMetricsTE = <E = ErrorResponse>(
  {
    userId,
    appId,
    modelId,
    versionId,
  }: {
    modelId: string;
    appId: string;
    userId: string;
    versionId: string;
  },
  onRejected?: (e: ErrorResponse) => E,
): TaskEither<E, CF.API.ModelVersionMetrics.ModelVersionMetricsResponse> =>
  fetchTE<CF.API.ModelVersionMetrics.ModelVersionMetricsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/models/${encodeURIComponent(modelId)}/versions/${versionId}/metrics`,
      method: 'POST',
      body: {},
    },
    onRejected,
  );
