import { updateAppTE } from 'api/apps';
import { updateModelTE } from 'api/models/updateModel';
import { updateWorkflowTE } from 'api/workflows/updateWorkflow';
import { updateModuleTE } from 'api/modules/updateModule';
import { updateDatasetTE } from 'api/datasets/updateDataset';
import { errorToReactLeft, pipe } from 'utils/fp';
import { taskEither } from 'fp-ts';
import { omit } from 'rambda';

export const ACTION_UPLOAD: Record<string, 'remove' | 'overwrite' | 'merge'> = {
  dataset: 'merge',
  module: 'overwrite',
  workflow: 'merge',
  model: 'merge',
  app: 'overwrite',
};

export const coverImageApiEffects = {
  updateAppTE: (userId: string, appId: string, image: CF.API.Image, action: 'remove' | 'overwrite' | 'merge') =>
    pipe(
      updateAppTE({ userId, body: { app: { id: appId, image }, action, reindex: false } }, errorToReactLeft),
      taskEither.map(({ app }) => app.image),
    ),
  updateModelTE: (userId: string, appId: string, modelId: string, image: CF.API.Image, action: 'remove' | 'overwrite' | 'merge') =>
    pipe(
      updateModelTE({ userId, appId, body: { models: [{ id: modelId, image }], action } }, errorToReactLeft),
      taskEither.map(({ models }) => models[0].image),
    ),
  updateWorkflowTE: (userId: string, appId: string, workflowId: string, image: CF.API.Image, action: 'remove' | 'overwrite' | 'merge') =>
    pipe(
      updateWorkflowTE({ userId, appId, body: { workflows: [{ id: workflowId, image }], action } }, errorToReactLeft),
      taskEither.map(({ workflows }) => workflows[0].image),
    ),
  updateModuleTE: (
    userId: string,
    appId: string,
    module: Partial<CF.API.Modules.Module>,
    image: CF.API.Image,
    action: 'remove' | 'overwrite' | 'merge',
  ) =>
    pipe(
      updateModuleTE(
        {
          userId,
          appId,
          body: { modules: ACTION_UPLOAD.module === action ? [{ ...omit(['module_version'], module), image }] : [{ id: module.id, image }], action },
        },
        errorToReactLeft,
      ),
      taskEither.map(({ modules }) => modules[0].image),
    ),
  updateDatasetTE: (userId: string, appId: string, datasetId: string, image: CF.API.Image, action: 'remove' | 'overwrite' | 'merge') =>
    pipe(
      updateDatasetTE({ userId, appId, body: { datasets: [{ id: datasetId, image }], action } }, errorToReactLeft),
      taskEither.map(({ datasets }) => datasets[0].image),
    ),
};
