import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const fetchIdentityProviderTE = <E = Error>(
  { orgId, identityProviderId }: { orgId: string; identityProviderId: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Organizations.FetchIdentityProviderResponse> =>
  fetchTE<CF.API.Organizations.FetchIdentityProviderResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/identity_providers/${identityProviderId}`,
      method: 'GET',
    },
    onRejected,
  );
