import { createContext, Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { JobMonitorExpanded } from 'modules/InputsJobMonitor/JobMonitorExpanded';
import { UploadJobType, useUploader, UploadHandler, UploadArchiveHandler, UpdateUploadJobHandler } from 'hooks/useUploader';

export type InputsJobMonitorContextType = {
  jobs: Record<string, UploadJobType>;
  upload: UploadHandler;
  uploadArchive: UploadArchiveHandler;
  updateUploadJob: UpdateUploadJobHandler;
  shouldRefresh: boolean;
  activeTab: TabStatus;
  isExpanded: boolean;
  setActiveTab: Dispatch<SetStateAction<TabStatus>>;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  setClassName: Dispatch<SetStateAction<string | undefined>>;
};

export enum TabStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export const InputsJobMonitorContext = createContext<InputsJobMonitorContextType>({} as InputsJobMonitorContextType);

export const InputsJobMonitorProvider: React.FC = ({ children }) => {
  const { upload, uploadArchive, updateUploadJob, jobs } = useUploader();
  const { pathname } = useRouter();
  const [isChecked, setIsChecked] = useState(true);
  const [activeTab, setActiveTab] = useState(TabStatus.Active);
  const [isExpanded, setIsExpanded] = useState(true);
  // Unfortunately, this is the only way to customize the component from outside.
  const [className, setClassName] = useState<string>();

  const contextValue = useMemo(
    () => ({
      jobs,
      upload,
      updateUploadJob,
      uploadArchive,
      shouldRefresh: isChecked,
      activeTab,
      isExpanded,
      setActiveTab,
      setIsExpanded,
      setClassName,
    }),
    [jobs, upload, uploadArchive, updateUploadJob, isChecked],
  );

  const onCheck = useCallback(() => {
    setIsChecked((crtIsCheckedFlag) => !crtIsCheckedFlag);
  }, []);

  return (
    <InputsJobMonitorContext.Provider value={contextValue}>
      {children}
      {pathname === '/[userOrOrgId]/[appId]/inputs' ? (
        <JobMonitorExpanded
          className={className}
          jobs={jobs}
          onCheck={onCheck}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          shouldRefresh={isChecked}
        />
      ) : null}
    </InputsJobMonitorContext.Provider>
  );
};
