import { css } from '@linaria/core';
import { theming } from 'styles/utils';
import { bigH1, textSmall } from 'styles/typography';
export const formGroup = "odules__Auth__components__Signup__Signup_styles_ts-formGroup-f796g7i";
export const formGroupLast = "odules__Auth__components__Signup__Signup_styles_ts-formGroupLast-f1bhqmpa";
export const logo = "odules__Auth__components__Signup__Signup_styles_ts-logo-lramqv0";
export const logoImage = "odules__Auth__components__Signup__Signup_styles_ts-logoImage-l8rltm9";
export const title = `${"odules__Auth__components__Signup__Signup_styles_ts-title-t12w415i"}
  ${bigH1}
`;
export const forms = `${"odules__Auth__components__Signup__Signup_styles_ts-forms-fpaxjwm"}`;
export const checkboxes = "odules__Auth__components__Signup__Signup_styles_ts-checkboxes-c14b1f6s";
export const fieldLabel = `${"odules__Auth__components__Signup__Signup_styles_ts-fieldLabel-f1mng6l8"}
`;
export const checkboxLabel = `$
  ${textSmall}
`;
export const textLink = "odules__Auth__components__Signup__Signup_styles_ts-textLink-t9bthxl";
export const captcha = "odules__Auth__components__Signup__Signup_styles_ts-captcha-c1r4pjpv";

require("./Signup.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Signup.styles.ts");