import { css, cx } from '@linaria/core';
import { baseUnits, flex, theming } from 'styles/utils';
import { textSmall } from 'components/Popover/Popover_styles';
import { inputStyles } from '../Input/Input.styles';
export const selectTriggerClassname = "omponents__Select__Select_styles_ts-selectTriggerClassname-s16ru4wf";
export const wrapper = "omponents__Select__Select_styles_ts-wrapper-w6eqqzh";
export const popover = "omponents__Select__Select_styles_ts-popover-p5go9db";
export const item = "omponents__Select__Select_styles_ts-item-ivtnqyt";
export const listWrapper = "omponents__Select__Select_styles_ts-listWrapper-lm7tosa";
export const displayField = cx(inputStyles, "omponents__Select__Select_styles_ts-displayField-dt22coz", flex.init, flex.alignItems.center);
export const displayFieldInline = "omponents__Select__Select_styles_ts-displayFieldInline-d1vilwr3";
export const itemPopoverText = `${"omponents__Select__Select_styles_ts-itemPopoverText-i1lfo4fo"}
  ${textSmall}
`;

require("./Select.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Select.styles.ts");