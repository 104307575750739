import { createStyleTools } from '../../../utils/responsive/index';

export const breakpointConfig = {
  mob: '320px',
  tab: '720px',
  bigtab: '1024px',
  mdpi: '1200px',
  hidpi: '1400px',
  hires: '1920px',
};

export const responsiveTools = { ...createStyleTools(breakpointConfig), breakpointConfig };
