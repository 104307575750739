import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { noop } from 'utils/fp';
import { wrapper, tab, tabActive } from './Tabs.styled';
export type EntityTab = {
  id: string;
  text: string;
  testid?: string;
};
export interface EntityTabListProps {
  tabs: EntityTab[];
  active: string;
  className?: string;
  tabClassName?: string;
  setActiveTab: (tab: string) => void;
}
export const Tabs: FC<EntityTabListProps> = observer(({
  className,
  tabClassName,
  tabs,
  active,
  setActiveTab,
  ...rest
}) => {
  return <nav className={cx(wrapper, className)} {...rest}>
      <ul className={cx(flex.init)}>
        {tabs.map(tabItem => {
        const isTabActive = active === tabItem.id;
        return <li key={tabItem.id}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="button" onKeyPress={noop} className={cx(tab, tabClassName, isTabActive && tabActive)} data-active={isTabActive} onClick={() => setActiveTab(tabItem.id)}>
              {tabItem.text}
            </li>;
      })}
      </ul>
    </nav>;
});