import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export type UpdateModelIdType = {
  ids: {
    id: string;
    new_id: string;
  }[];
  action: 'overwrite';
};

export const updateModelIdTE = <E = Error | CF.API.Models.ListModelsResponse>(
  {
    userId,
    appId,
    body,
  }: {
    userId: string;
    appId: string;
    body: UpdateModelIdType;
  },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Models.ListModelsResponse> =>
  fetchTE<CF.API.Models.ListModelsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/models/ids`,
      method: 'PATCH',
      body,
    },
    onRejected,
  );
