import type { TaskEither } from 'fp-ts/lib/TaskEither';
import type { Either } from 'fp-ts/lib/Either';
import { fetchTE } from 'utils/request';
import { from, Observable } from 'rxjs';

export const fetchAppTE = <E = Error>(
  { appId, userId, params = '', sessionToken }: { appId: string; userId?: string; params?: string; sessionToken?: string },
  onRejected?: (e: Error) => E,
  signal?: AbortSignal,
): TaskEither<E, CF.API.Apps.FetchAppResponse> =>
  fetchTE<CF.API.Apps.FetchAppResponse, E>(
    {
      path: userId ? `/api/_api/v2/users/${userId}/apps/${appId}${params ? `?${params}` : ''}` : `/api/_api/v2/apps/${appId}`,
      method: 'GET',
      signal,
      sessionToken,
    },
    onRejected,
  );

export function fromTaskEither<Left, Right>(
  fetcher: (opts: Record<string, unknown>, onRejected?: () => Left, signal?: AbortSignal) => TaskEither<Left, Right>,
  requestParams: Record<string, unknown>,
  onRejected?: () => Left,
): Observable<Either<Left, Right>> {
  return new Observable<Either<Left, Right>>((observer) => {
    const abortController = new AbortController();
    const subscription = from(fetcher(requestParams, onRejected, abortController.signal)()).subscribe(observer);

    return () => {
      abortController.abort();
      subscription.unsubscribe();
    };
  });
}
