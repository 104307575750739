import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const patchOrgTE = <E = Error>(
  {
    organization,
  }: {
    params?: string;
    organization: Pick<CF.API.Organizations.Organization, 'id' | 'name' | 'billing_email'>;
  },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Organizations.CreateOrgResponse> =>
  fetchTE<CF.API.Organizations.CreateOrgResponse, E>(
    {
      path: `/api/_api/v2/organizations/${organization.id}`,
      method: 'PATCH',
      body: {
        organizations: [organization],
        action: 'overwrite',
      },
    },
    onRejected,
  );
