import { cx, css } from '@linaria/core';
import { IconAccount, IconTeam, IconSecurity } from 'components/Icons';
import { MenuItem } from 'modules/Sidebar/components/Menu/Menu';
import { cssHelpers } from 'styles/utils/core';
import { useCurrentUserOrg } from 'modules/Organizations/hocs';
import { ORGANIZATION_ROLE_ID_ADMIN, ORGANIZATION_ROLE_ID_MEMBER_MIN } from 'modules/Organizations/constants';
import { BaseUserSettingsSidebar } from 'modules/User/components/Sidebar/BaseUserSettingsSidebar';
const divider = cx("odules__Organizations__components__Sidebar__Sidebar_tsx-divider-d5gfmli", 'fullWidth', cssHelpers.margin.y[1]);
export const LinkList: React.FC = () => {
  const org = useCurrentUserOrg();
  const isAdminOrOrgMember = org?.role.id !== ORGANIZATION_ROLE_ID_MEMBER_MIN;
  const isAdmin = org?.role.id === ORGANIZATION_ROLE_ID_ADMIN;
  return <>
      <MenuItem href="/settings/organization" icon={IconAccount}>
        Account
      </MenuItem>
      {isAdmin && <MenuItem href="/settings/organization/security" icon={IconSecurity}>
          Security
        </MenuItem>}
      <div className={divider} />
      {isAdminOrOrgMember && <>
          <MenuItem href="/settings/organization/members" icon={IconSecurity}>
            Members
          </MenuItem>
          <MenuItem href="/settings/organization/teams" icon={IconTeam}>
            Teams
          </MenuItem>
        </>}
    </>;
};
export const Sidebar: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => <BaseUserSettingsSidebar {...props}>
    <LinkList />
  </BaseUserSettingsSidebar>;

require("./Sidebar.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./Sidebar.tsx");