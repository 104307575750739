import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { listStarredAppsTE } from 'api/apps';
import { ComponentMap, DefaultLeftProps, ReactLeft, UI_STATES } from 'utils/uiStates/uiStates';
import { types } from 'mst-effect';
import { makeSearchable } from 'modules/Listings/store/models/Searchable';
import { createListable } from 'modules/Listings/store/models/Listable';
import { makeSortable } from 'modules/Listings/store/models/Sortable';
import { makeUISettings } from 'modules/Listings/store/models/UiSettings';
import { getIsEntityOwner } from 'modules/Auth/hooks';
import type { QueryClient } from 'react-query';
import { errorToReactLeft } from 'utils/fp';
import { defineNotFilterable } from 'modules/Listings/store/models/Filterable';
import { FILTERS, FILTER_PARAMS } from 'modules/Listings/components/AllStarTemplateToggle';
import { appSortCriteria } from 'modules/Apps/listingStore/sortCriteria';
import { appMST } from 'modules/Apps/listingStore/mst-types';
import { makeAppsUrl } from 'modules/Apps/listingStore/createAppsList';
import { NoStarredTemplateResource } from 'components/NoAppState/NoStarredResource';
import { SupportedListingEntities } from 'modules/Listings/types';

export const makeFetchAppTemplates = (userId?: string) =>
  function fetchAppsTE(params: string): TaskEither<ReactLeft, CF.API.Apps.App[]> {
    return pipe(
      listStarredAppsTE({ userId, params: `${params}&template_only=true` }, errorToReactLeft),
      taskEither.map((x) => x.apps),
    );
  };

export const EmptyState = () => {
  return (
    <NoStarredTemplateResource
      title="There are no templates yet."
      subtitle="Mark an App as a template and use it to create new Apps fast."
      isTemplate
    />
  );
};

// inference works better than explicit type definition for MST
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createAppTemplatesList = ({
  queryClient,
  userIdFromRouter,
  initialQuery,
  initialList,
  activeToggle,
  uiStateMap,
  overrideUrlClick,
  disableRouterUpdates = true,
}: {
  queryClient: QueryClient;
  userIdFromRouter?: string;
  initialQuery?: Record<string, string>;
  initialList?: CF.API.Apps.App[];
  activeToggle?: string;
  disableRouterUpdates?: boolean;
  uiStateMap?: ComponentMap<DefaultLeftProps>;
  overrideUrlClick?: (entity: SupportedListingEntities) => void;
}) => {
  const AppTemplatesList = types
    .compose(
      createListable({
        fetcher: makeFetchAppTemplates(userIdFromRouter),
        entityMstModel: appMST,
        listQueryKey: ['appTemplates', { userIdFromRouter }],
        initialQuery,
        disableRouterUpdates,
      }),
      makeSearchable(initialQuery, { disableRouterUpdates }),
      makeSortable(initialQuery, { disableRouterUpdates }),
      makeUISettings(initialQuery, { disableRouterUpdates }),
      defineNotFilterable(),
    )
    .volatile(() => ({
      sortCriteria: appSortCriteria,
      makeUrl: makeAppsUrl,
      getIsEntityOwner,
      overrideUrlClick,
      getUiStateMap: () => ({ [UI_STATES.empty]: EmptyState, ...(uiStateMap || {}) }),
    }))
    .named('AppTemplatesList');

  const instance = AppTemplatesList.create(
    {
      _listItems: initialList || [],
      defaultParams: FILTERS.find((filter) => filter.name === activeToggle)?.filterParams || FILTER_PARAMS.All,
    },
    { sortCriteria: appSortCriteria, queryClient },
  );
  return instance;
};

export type appTemplatesListType = ReturnType<typeof createAppTemplatesList>;

export const testable = {
  createAppTemplatesList,
};
