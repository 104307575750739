import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const listTeamsTE = <E = Error>(
  { orgId, params = '' }: { orgId?: string; params?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Organizations.ListTeamsResponse> =>
  fetchTE<CF.API.Organizations.ListTeamsResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/teams${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );
