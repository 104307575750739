/* eslint-disable @typescript-eslint/ban-types */
import React, { Fragment, useCallback, useRef } from 'react';
import { cx } from '@linaria/core';
import { Menu, MenuButton, MenuItem, MenuStateReturn } from 'reakit/Menu';
import { popoverWrapper } from 'components/Popover/Popover_styles';
import { cssHelpers } from 'styles/utils/core';
import { displayField, displayFieldInline, item, listWrapper, popover, selectTriggerClassname, wrapper, itemPopoverText } from './Select.styles';
import { SegmentTitle, TextSmall } from '../Popover';
import { DownArrow } from './DownArrow';
type SimpleSpread<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;
export type SelectOption = {
  name: string;
  value: string;
  content?: JSX.Element;
  key?: string;
};
export interface NestedSelectItem<T> {
  list: (SelectOption & T)[];
  segmentTitle?: React.ReactNode;
}
export interface SelectPropsExtra<ItemExtras> {
  title?: string | React.ReactNode;
  options: (SelectOption & ItemExtras)[] | NestedSelectItem<ItemExtras>[];
  onClick?: () => void;
  ItemWrapper?: React.FC<{
    item?: SelectPropsExtra<ItemExtras>['options'];
    className?: string;
  }>;
  inline?: boolean;
  trigger?: React.ReactNode;
  selectElementClassName?: string;
  onChange?: (o: (SelectOption & ItemExtras) | NestedSelectItem<ItemExtras>['list'][0]) => void;
  label?: string;
}
export interface ControlledSelectProps<ItemExtras> extends BaseSelectProps<ItemExtras> {
  menuStateReturn: MenuStateReturn;
  disabled?: boolean;
  modal?: boolean;
  footer?: JSX.Element | null;
  menuWrapperClass?: string;
}
export interface BaseSelectProps<ItemExtras = Record<string, unknown>> extends SimpleSpread<React.HTMLAttributes<HTMLDivElement>, SelectPropsExtra<ItemExtras>> {}
export function SelectControlled<ItemExtras extends unknown>({
  options,
  onChange,
  title,
  ItemWrapper = TextSmall,
  menuStateReturn,
  className,
  inline,
  trigger,
  disabled,
  modal,
  label,
  selectElementClassName,
  footer = null,
  menuWrapperClass,
  ...rest
}: ControlledSelectProps<ItemExtras>): JSX.Element | null {
  const menuRef = useRef<HTMLButtonElement>(null);
  const clickHandler = useCallback((o: SelectOption & ItemExtras) => {
    if ('value' in o && onChange) {
      onChange(o);
    }
    menuStateReturn.hide();
  }, [onChange, menuStateReturn.hide]);
  const isNested = options.length > 0 && 'segmentTitle' in options[0];
  return <div className={cx(wrapper, className)} {...rest}>
      <MenuButton {...menuStateReturn} className={cx(inline ? displayFieldInline : displayField, selectTriggerClassname, selectElementClassName)} disabled={disabled} type="button" ref={menuRef}>
        {trigger ? <span className="select-title">{trigger}</span> : <span className="select-title">{title}</span>}
        <DownArrow className="caret-down" />
      </MenuButton>
      <Menu {...menuStateReturn} modal={modal} className={cx(cssHelpers.zIndex.max, 'menu_wrapper')} aria-label={label}>
        <div className={cx(popoverWrapper, popover, listWrapper, 'boundToParent', menuWrapperClass)} style={{
        width: menuRef.current?.getBoundingClientRect().width,
        padding: '1rem 0'
      }}>
          {!isNested ? (options as (SelectOption & ItemExtras)[]).map(option => <MenuItem {...menuStateReturn} id={option.value} key={option.key || option.content && option.content.key || option.value} onClick={() => clickHandler(option)} className={cx(item, 'popoverPadding')} type="button">
                  <ItemWrapper className={itemPopoverText}>{option.content || option.name}</ItemWrapper>
                </MenuItem>) : (options as NestedSelectItem<ItemExtras>[]).map((listItem, i) =>
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={i}>
                  <SegmentTitle className="popoverPadding">{listItem.segmentTitle}</SegmentTitle>
                  {listItem.list.map(option => <MenuItem {...menuStateReturn} id={option.value} key={option.key || option.content && option.content.key || option.value} onClick={() => clickHandler(option)} className={cx(item, 'popoverPadding')} type="button">
                      <ItemWrapper className={itemPopoverText}>{option.content || option.name}</ItemWrapper>
                    </MenuItem>)}
                </Fragment>)}
          {footer}
        </div>
      </Menu>
    </div>;
}
SelectControlled.defaultProps = {
  inline: false
};