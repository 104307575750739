import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';
import { pipe } from 'utils/fp';
import { option } from 'fp-ts';

type Res = CF.API.Models.ListModelVersions.Response;

export const listModelVersionsTE = <E = Error>(
  { userId, appId, modelId, params = '', sessionToken }: { modelId: string; appId: string; userId: string; params?: string; sessionToken?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, Res> => {
  const query = pipe(
    option.fromNullable(params),
    option.map((_params) => `?${_params}`),
    option.getOrElse(() => ''),
  );
  return fetchTE<Res, E>(
    {
      sessionToken,
      path: `/api/_api/v2/users/${userId}/apps/${appId}/models/${encodeURIComponent(modelId)}/versions${query}`,
      method: 'GET',
    },
    onRejected,
  );
};
