import { FC } from 'react';
import { IconProps } from 'components/ModelTypeIcon/types';
import {
  AnnotationWriterIcon,
  AudioEmbedderIcon,
  AudioToTextIcon,
  BarcodeOperatorIcon,
  ClustererIcon,
  ConceptThresholderIcon,
  ContextBasedClassifierIcon,
  DefaultModelTypeIcon,
  EmailIcon,
  ImageColorRecognizerIcon,
  ImageCropIcon,
  ImageToTextIcon,
  KNNClassifierIcon,
  MultiModalEmbedderIcon,
  MultiModalToTextIcon,
  OpticalCharacterRecognizerIcon,
  RandomSampleIcon,
  SMSIcon,
  TesseractOperatorIcon,
  TextClassifierIcon,
  TextEmbedderIcon,
  TextToAudioIcon,
  TextTokenClassifierIcon,
  VisualClassifierIcon,
  VisualDetectorEmbedderIcon,
  VisualDetectorIcon,
  VisualEmbedderIcon,
  VisualLandmarksIcon,
  VisualSegmenterIcon,
} from 'components/ModelTypeIcon/icons';
import { LayerThreeIcon } from './icons/LayerThreeIcon';

export const DEFAULT_ICON_SIZE = 16;

export const colors: { [id: string]: { textColor: string; bgColor: string; shadow: string } } = {
  classifier: {
    textColor: '#9E165F',
    bgColor: '#FDF2FA',
    shadow: '#FCE7F6',
  },
  embedder: {
    textColor: '#93370D',
    bgColor: '#FFFAEB',
    shadow: '#FEF0C7',
  },
  recognizer: {
    textColor: '#003668',
    bgColor: '#F1F8FF',
    shadow: '#DCEDFF',
  },
  toText: {
    textColor: '#564600',
    bgColor: '#FFFBEA',
    shadow: '#FFF3C0',
  },
  operator: {
    textColor: '#4A1FB8',
    bgColor: '#F4F3FF',
    shadow: '#EBE9FE',
  },
  detector: {
    textColor: '#006604',
    bgColor: '#F3FFF4',
    shadow: '#D6FFDA',
  },
  landmarks: {
    textColor: '#5900B1',
    bgColor: '#FBF6FF',
    shadow: '#F6EDFF',
  },
  segmenter: {
    textColor: '#004C5C',
    bgColor: '#EFFCFF',
    shadow: '#D5F7FF',
  },
  clusterer: {
    textColor: '#003EBC',
    bgColor: '#F2F6FE',
    shadow: '#E6EDFD',
  },
  default: {
    // Same as clusterer for now
    textColor: '#003EBC',
    bgColor: '#F2F6FE',
    shadow: '#E6EDFD',
  },
};

export const TypeToIconInfoMap: {
  [modelTypeId: string]: { textColor: string; bgColor: string; shadow: string; IconElement: FC<IconProps>; title?: string };
} = {
  // Classifiers
  'embedding-classifier': { ...colors.classifier, title: 'Transfer Learn Classifier', IconElement: ContextBasedClassifierIcon },
  'text-classifier': { ...colors.classifier, title: 'Text Classifier', IconElement: TextClassifierIcon },
  'text-token-classifier': { ...colors.classifier, title: 'Text Token Classifier', IconElement: TextTokenClassifierIcon },
  'visual-classifier': { ...colors.classifier, title: 'Visual Classifier', IconElement: VisualClassifierIcon },
  'knn-concept': { ...colors.classifier, title: 'KNN Classifier', IconElement: KNNClassifierIcon }, // KNN Classifier
  // Embedders
  'audio-embedder': { ...colors.embedder, title: 'Audio Embedder', IconElement: AudioEmbedderIcon },
  'visual-embedder': { ...colors.embedder, title: 'Visual Embedder', IconElement: VisualEmbedderIcon },
  'text-embedder': { ...colors.embedder, title: 'Text Embedder', IconElement: TextEmbedderIcon },
  'multimodal-embedder': { ...colors.embedder, title: 'Multimodal Embedder', IconElement: MultiModalEmbedderIcon },
  // Recognizers
  'image-color-recognizer': { ...colors.recognizer, title: 'Image Color Recognizer', IconElement: ImageColorRecognizerIcon },
  'optical-character-recognizer': { ...colors.recognizer, title: 'Optical Character Recognizer (OCR)', IconElement: OpticalCharacterRecognizerIcon },
  // ToText
  'audio-to-text': { ...colors.toText, title: 'Audio To Text', IconElement: AudioToTextIcon },
  'text-to-audio': { ...colors.toText, title: 'Text To Audio', IconElement: TextToAudioIcon },
  'image-to-text': { ...colors.toText, title: 'Image To Text', IconElement: ImageToTextIcon },
  'multimodal-to-text': { ...colors.toText, title: 'Image To Text', IconElement: MultiModalToTextIcon },
  // Operators
  'barcode-operator': { ...colors.operator, title: 'Barcode Operator', IconElement: BarcodeOperatorIcon },
  'tesseract-operator': { ...colors.operator, title: 'Tesseract Operator', IconElement: TesseractOperatorIcon },
  // Detectors
  'visual-detector': { ...colors.detector, title: 'Visual Detector', IconElement: VisualDetectorIcon },
  'visual-detector-embedder': { ...colors.detector, title: 'Visual Detector + Embedder', IconElement: VisualDetectorEmbedderIcon },
  llm: { ...colors.detector, title: 'Foundation', IconElement: LayerThreeIcon },
  // Landmarks
  landmarks: { ...colors.landmarks, title: 'Visual Landmarks', IconElement: VisualLandmarksIcon },
  // Segmenter
  'visual-segmenter': { ...colors.segmenter, title: 'Visual Segmenter', IconElement: VisualSegmenterIcon },
  // Clusterer
  clusterer: { ...colors.clusterer, title: 'Clusterer', IconElement: ClustererIcon },

  // Default for Icons that are not yet implemented
  default: { ...colors.default, IconElement: DefaultModelTypeIcon },

  // TODO(mkask): Icons from public/icons/model to be replaced with new design
  'annotation-writer': { ...colors.default, title: 'Annotation Writer', IconElement: AnnotationWriterIcon },
  'concept-thresholder': { ...colors.default, title: 'Concept Thresholder', IconElement: ConceptThresholderIcon },
  email: { ...colors.default, title: 'Email Alert', IconElement: EmailIcon },
  'image-crop': { ...colors.default, title: 'Image Cropper', IconElement: ImageCropIcon },
  'random-sample': { ...colors.default, title: 'Random Sampler', IconElement: RandomSampleIcon },
  sms: { ...colors.default, title: 'SMS Alert', IconElement: SMSIcon },

  // New Icon designs missing for these model types, supplying only titles for now:
  'text-to-image': { ...colors.detector, title: 'Text To Image', IconElement: ImageToTextIcon },
  'image-to-image': { ...colors.detector, title: 'Image To Image', IconElement: ImageCropIcon },
  'region-thresholder': { ...colors.default, title: 'Region Thresholder', IconElement: DefaultModelTypeIcon },
  'concept-synonym-mapper': { ...colors.default, title: 'Concept Synonym Mapper', IconElement: DefaultModelTypeIcon },
  'visual-keypointer': { ...colors.default, title: 'Visual Keypoint', IconElement: DefaultModelTypeIcon },
  'status-push': { ...colors.default, title: 'Status Push', IconElement: DefaultModelTypeIcon },
  'results-push': { ...colors.default, title: 'Results Push', IconElement: DefaultModelTypeIcon },
  'aws-lambda': { ...colors.default, title: 'AWS Lambda', IconElement: DefaultModelTypeIcon },
  'custom-code-operator': { ...colors.default, title: 'Custom Code Operator', IconElement: DefaultModelTypeIcon },
  'object-counter': { ...colors.default, title: 'Object Counter', IconElement: DefaultModelTypeIcon },
  'image-align': { ...colors.default, title: 'Image Align', IconElement: DefaultModelTypeIcon },
  'input-searcher': { ...colors.default, title: 'Cross-App Input Searcher', IconElement: DefaultModelTypeIcon },
  'input-filter': { ...colors.default, title: 'Input Filter', IconElement: DefaultModelTypeIcon },
  'isolation-operator': { ...colors.default, title: 'Isolation Operator', IconElement: DefaultModelTypeIcon },
  'language-id-operator': { ...colors.default, title: 'Language Identification Operator', IconElement: DefaultModelTypeIcon },
  'keyword-filter-operator': { ...colors.default, title: 'Keyword Filter Operator', IconElement: DefaultModelTypeIcon },
  'text-aggregation-operator': { ...colors.default, title: 'Text Aggregation Operator', IconElement: DefaultModelTypeIcon },
  'tokens-to-entity-operator': { ...colors.default, title: 'Tokens to Entity Operator', IconElement: DefaultModelTypeIcon },
  'track-representation-operator': { ...colors.default, title: 'Track Representation Operator', IconElement: DefaultModelTypeIcon },
  'kalman-reid-tracker': { ...colors.default, title: 'Kalman Tracker w/ re-ID', IconElement: DefaultModelTypeIcon },
  'neural-lite-tracker': { ...colors.default, title: 'Neural Lite Tracker', IconElement: DefaultModelTypeIcon },
  'neural-tracker': { ...colors.default, title: 'Neural Tracker', IconElement: DefaultModelTypeIcon },
  'centroid-tracker': { ...colors.default, title: 'Centroid Tracker', IconElement: DefaultModelTypeIcon },
  'kalman-filter-tracker': { ...colors.default, title: 'Kalman Filter Hungarian Tracker', IconElement: DefaultModelTypeIcon },
  'zero-shot-image-classifier': { ...colors.default, title: 'Zero Shot Image Classifier', IconElement: DefaultModelTypeIcon },
  'zero-shot-text-classifier': { ...colors.default, title: 'Zero Shot Text Classifier', IconElement: DefaultModelTypeIcon },
  'text-to-text': { ...colors.default, title: 'Text To Text', IconElement: DefaultModelTypeIcon },
  'audio-classifier': { ...colors.default, title: 'Audio Classifier', IconElement: DefaultModelTypeIcon },
  'visual-anomaly-heatmap': { ...colors.default, title: 'Visual Anomaly', IconElement: DefaultModelTypeIcon },
};
