import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export type resendVerificationEmailRequestBody = {
  email: string;
};

export const resendVerificationEmailTE = <E = Error>(
  { body }: { body: resendVerificationEmailRequestBody },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Users.AddEmailResponse> =>
  fetchTE<CF.API.Users.AddEmailResponse, E>(
    {
      path: '/api/_api/v2/users/me/resend_verification',
      method: 'POST',
      body,
    },
    onRejected,
  );
