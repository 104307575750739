import ClientCookies, { CookieAttributes } from 'js-cookie';
import ServerCookies from 'cookies';
import { IncomingMessage, ServerResponse } from 'http';

export const PREFIX = 'x_clarifai_';

export const COOKIE_KEYS = {
  sessionToken: 'session_token',
};

export const cookieClientStorage = {
  get: (propName: string) => {
    return ClientCookies.get(`${PREFIX}${propName}`);
  },

  set: (propName: string, value: string | Record<string, string>) => {
    const options: CookieAttributes =
      typeof window !== 'undefined' && window.location.host.includes('clarifai.com')
        ? { domain: '.clarifai.com', secure: true, sameSite: 'none' }
        : {};
    return ClientCookies.set(`${PREFIX}${propName}`, value, options);
  },
  setDomainLevel: (propName: string, value: string | Record<string, string>) => {
    const cookieOptions: CookieAttributes = { domain: 'clarifai.com', path: '/', secure: true, sameSite: 'none' };
    return ClientCookies.set(`${PREFIX}${propName}`, value, cookieOptions);
  },
  rm: (...args: Parameters<typeof ClientCookies.remove>) => {
    ClientCookies.remove(`${PREFIX}${args[0]}`, args[1]);
  },
};

type CookieServerReturnType = { get: (propName: string) => string | undefined; set: (propName: string, value: string) => void };

export const createCookieServerStorage = (req: IncomingMessage, res: ServerResponse): CookieServerReturnType => {
  const serverCookies = new ServerCookies(req, res);

  return {
    get: (propName) => {
      return serverCookies.get(`${PREFIX}${propName}`);
    },
    set: (propName: string, value: string) => {
      serverCookies.set(`${PREFIX}${propName}`, value);
    },
  };
};
