import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const deleteModelVersionTE = <E = Error>(
  {
    userId,
    appId,
    modelId,
    modelVersionId,
    params = '',
  }: { userId: string; appId: string; modelId: string; modelVersionId: string; params?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Status> =>
  fetchTE<CF.API.Status, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/models/${encodeURIComponent(modelId)}/versions/${encodeURIComponent(modelVersionId)}${
        params ? `?${params}` : ''
      }`,
      method: 'DELETE',
    },
    onRejected,
  );
