import { css, cx } from '@linaria/core';
import { baseUnits, theming, flex, breakpoints } from 'styles/utils';
import { inputInfo, textRegularLight, textSmall } from 'styles/typography';
const maxContainerWidth = '120rem';
const primaryBg = '#004EEB';
export const annotation = `${"odules__Auth__components__styles_ts-annotation-aa3ang5"}
  ${textSmall}
`;
export const authWrapper = "odules__Auth__components__styles_ts-authWrapper-ai7dznc";
export const authBannerBackground = `${"odules__Auth__components__styles_ts-authBannerBackground-a1o4jzp9"}
  ${flex.init}
  ${flex.direction.column}
  ${flex.justifyContent.center}
  ${flex.alignItems.center}
`;
export const authBannerLogin = "odules__Auth__components__styles_ts-authBannerLogin-a1ccz62s";
export const authBannerWrapper = "odules__Auth__components__styles_ts-authBannerWrapper-aj8i4qp";
export const BannerWrapper = "odules__Auth__components__styles_ts-BannerWrapper-buqbpk2";
export const Content = "odules__Auth__components__styles_ts-Content-c1177jxa";
export const loginBannerImage = "odules__Auth__components__styles_ts-loginBannerImage-l1cg10qp";
export const logoLink = "odules__Auth__components__styles_ts-logoLink-lbwtdec";
export const LogoImage = "odules__Auth__components__styles_ts-LogoImage-llew7tn";
export const formWrapper = "odules__Auth__components__styles_ts-formWrapper-ftpz8u";
export const authContainer = "odules__Auth__components__styles_ts-authContainer-ahfbni1";
export const authContent = "odules__Auth__components__styles_ts-authContent-a49mtv6";
export const footerContainer = "odules__Auth__components__styles_ts-footerContainer-f1buqzl";
export const footerWrapper = `${"odules__Auth__components__styles_ts-footerWrapper-f16jk7a4"}

  ${flex.init}
`;
export const footerLinkWrapper = "odules__Auth__components__styles_ts-footerLinkWrapper-f17t465z";
export const rowInputs = "odules__Auth__components__styles_ts-rowInputs-r146z0jx";
export const clientLogo = "odules__Auth__components__styles_ts-clientLogo-ce9vjjs";
export const heading = "odules__Auth__components__styles_ts-heading-h1da17jc";
export const textDefault = "odules__Auth__components__styles_ts-textDefault-t50gnrx";
export const textBold = `${"odules__Auth__components__styles_ts-textBold-t1280hk5"}
  ${textDefault}
`;
export const textBlack = `${"odules__Auth__components__styles_ts-textBlack-t7y4q21"}
  ${textDefault}
`;
export const textGrey = `${"odules__Auth__components__styles_ts-textGrey-txlh48f"}
  ${textDefault}
`;
export const textLink = "odules__Auth__components__styles_ts-textLink-t1vn4rur";
export const textLinkExternal = "odules__Auth__components__styles_ts-textLinkExternal-t1uiy6ai";
export const textLinkGreyExternal = "odules__Auth__components__styles_ts-textLinkGreyExternal-t1gu4o9w";
export const textLegal = "odules__Auth__components__styles_ts-textLegal-t1yc5ji1";
export const checkboxLabel = "odules__Auth__components__styles_ts-checkboxLabel-c1u83gqo";
export const buttonAuth = "odules__Auth__components__styles_ts-buttonAuth-bezl1y5";
export const buttonDisabled = "odules__Auth__components__styles_ts-buttonDisabled-bhmqfwq";
export const authCheckboxImage = "odules__Auth__components__styles_ts-authCheckboxImage-ad4cj7a";
export const authHeading = "odules__Auth__components__styles_ts-authHeading-a152jw2j";
export const authSubHeading = "odules__Auth__components__styles_ts-authSubHeading-a1y3psvn";
export const _description = "odules__Auth__components__styles_ts-_description-_15qptpo";
export const authDescription = `${"odules__Auth__components__styles_ts-authDescription-a13zi4am"}
${_description}
`;
export const loginBannerDescription = `${"odules__Auth__components__styles_ts-loginBannerDescription-l7tlc59"}
${_description}
`;
export const SignupContentWrapper = "odules__Auth__components__styles_ts-SignupContentWrapper-s16ffjrt";
export const signupForm = "odules__Auth__components__styles_ts-signupForm-s1o7knwr";
export const signupFormNameGroup = `${"odules__Auth__components__styles_ts-signupFormNameGroup-s10rx8z0"}
  ${flex.init}
  ${flex.justifyContent.between}
`;
export const formGridWrapper = `${"odules__Auth__components__styles_ts-formGridWrapper-f9oiu8f"}

${flex.init}
${flex.direction.column}
`;
export const footerMobile = "odules__Auth__components__styles_ts-footerMobile-f1xrnjdb";
export const rememberMeGroup = "odules__Auth__components__styles_ts-rememberMeGroup-rc4bc07";
export const links = cx("odules__Auth__components__styles_ts-links-l1092hbp", textRegularLight);
export const labelDescription = cx(inputInfo, textRegularLight);
export const ssoProviderWrapper = "odules__Auth__components__styles_ts-ssoProviderWrapper-sm0ctkd";
export const ssoButton = "odules__Auth__components__styles_ts-ssoButton-s7nqb3h";
export const ssoProviderContainer = "odules__Auth__components__styles_ts-ssoProviderContainer-s13fyuvm";
export const ssoProviderSpinner = "odules__Auth__components__styles_ts-ssoProviderSpinner-srskmce";

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./styles.ts");