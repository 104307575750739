import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const deleteOrgTE = <E = Error>(
  {
    organization,
  }: {
    params?: string;
    organization: Pick<CF.API.Organizations.Organization, 'id'>;
  },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/organizations/${organization.id}`,
      method: 'DELETE',
    },
    onRejected,
  );

export const leaveOrgTE = <E = Error>(
  {
    organization,
  }: {
    params?: string;
    organization: Pick<CF.API.Organizations.Organization, 'id'>;
  },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/organizations/${organization.id}/leave`,
      method: 'DELETE',
    },
    onRejected,
  );
