import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

// types, page, perPage - endpoint supports these params
export const fetchRolesTE = <E = Error>(params?: string, onRejected?: (e: Error) => E): TaskEither<E, CF.API.Organizations.ListRolesResponse> =>
  fetchTE<CF.API.Organizations.ListRolesResponse, E>(
    {
      path: `/api/_api/v2/roles${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );
