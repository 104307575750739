import { cx } from '@linaria/core';
import type { ButtonProps as RBtnProps } from 'reakit';
import { cssHelpers } from 'styles/utils/core';
import { IconProps } from 'components/Icons/IconProps';
import { VariantTypes, StyledButton } from './Button.styles';
export type IconButtonProps = RBtnProps & {
  size?: 'sm' | 'md' | number;
  variant?: VariantTypes;
  Icon: React.ElementType<IconProps>;
  'aria-label': string;
  iconSize?: number;
  iconClassName?: string;
};
export const IconButton: import('react').VFC<IconButtonProps> = ({
  Icon,
  size = 'sm',
  variant = 'primary',
  disabled,
  className,
  iconClassName,
  iconSize,
  children,
  ...rest
}) => {
  const isNumericSize = typeof size === 'number';
  const defaultIconSize = isNumericSize ? (size as number) : size === 'sm' ? 14 : 16;
  return <StyledButton {...rest} className={cx('icon-only', `btn`, className, !isNumericSize && (size as string), disabled && 'disabled')} disabled={disabled} variant={variant}>
      <Icon data-testid="button-icon" className={cx(cssHelpers.margin.x[0], iconClassName)} size={iconSize || defaultIconSize} />
      {children}
    </StyledButton>;
};