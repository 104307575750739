import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const deleteTeamTE = <E = Error>(
  {
    orgId,
    body,
  }: {
    orgId: string;
    body: {
      ids: string[];
    };
  },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.BaseResponse> =>
  fetchTE<CF.API.BaseResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/teams`,
      method: 'DELETE',
      body,
    },
    onRejected,
  );
