import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const addTeamAppsTE = <E = Error>(
  {
    orgId,
    teamId,
    body,
  }: {
    orgId: string;
    teamId: string;
    body: {
      apps: {
        role_id: string;
        app_id: string;
      }[];
    };
  },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Organizations.ListTeamMembersResponse> =>
  fetchTE<CF.API.Organizations.ListTeamMembersResponse, E>(
    {
      path: `/api/_api/v2/organizations/${orgId}/teams/${teamId}/apps`,
      method: 'POST',
      body,
    },
    onRejected,
  );
