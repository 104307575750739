import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const updateAppVisibilityTE = <E = Error>(
  { userId, appId, visibility }: { userId: string; appId: string; visibility: CF.API.Visibility },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Apps.ListAppsResponse> =>
  fetchTE<CF.API.Apps.ListAppsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps`,
      method: 'PATCH',
      body: {
        apps: [
          {
            id: appId,
            visibility,
          },
        ],
        action: 'overwrite',
      },
    },
    onRejected,
  );
