import { useCallback } from 'react';
import { either, taskEither } from 'fp-ts';
import cogoToast from 'cogo-toast';
import { errorToReactLeft, pipe } from 'utils/fp';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { fetchRolesTE, deleteTeamTE } from 'api/organizations';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { useModalActions } from 'components/ModalManager/Context';
import type { TaskEither } from 'fp-ts/lib/TaskEither';
import type { TOrganizationsMST } from 'modules/Organizations/mstStores/OrganizationMST';
import { InviteModal, DeleteModal } from 'modules/Organizations/components/Modals';
import { useQueryClient } from 'react-query';
import { useAuthState } from 'modules/Auth/AuthContext';
import { listUserOrgInvitationsTE } from 'api/organizations/listUserOrgInvitations';

const DELETE_TEAM_MODAL_ID = 'delete_team_dialog';
const DELETE_INVITE_MODAL_ID = 'delete_invite_dialog';
const REMOVE_MEMBER_MODAL_ID = 'remove_member_dialog';
const INVITE_MODAL_ID = 'invite_members_dialog';

const apiEffects = {
  deleteTeamTE: (orgId: string, body: { ids: string[] }): TaskEither<ReactLeft, CF.API.BaseResponse> =>
    pipe(deleteTeamTE({ orgId, body }, errorToReactLeft)),
  listUserOrgInvitationsTE: (userId: string = '') =>
    pipe(
      listUserOrgInvitationsTE({ userId }, errorToReactLeft),
      taskEither.map(({ invitations }) => invitations),
    ),
};

export function useRoles(type: CF.API.Organizations.RoleType): { value: string; name: string }[] {
  const userId = useCurrentUserId();
  const { data } = useQueryTE([queryKeys.Roles, { userOrOrgId: userId }], fetchRolesTE(`types=${type}`, errorToReactLeft), {
    enabled: Boolean(userId),
  });

  return pipe(
    data,
    either.map(({ roles }) => roles.map((role) => ({ name: role.name, value: role.id }))),
    either.getOrElse<ReactLeft, { value: string; name: string }[]>(() => []),
  );
}

export function useInviteModal(store: TOrganizationsMST): () => void {
  const { openModal, closeModal } = useModalActions();

  const onClose = (): void => {
    closeModal({ id: INVITE_MODAL_ID });
  };

  return useCallback((): void => {
    openModal({
      id: INVITE_MODAL_ID,
      title: '',
      content: <InviteModal store={store} onClose={onClose} />,
      makeActions: () => null,
    });
  }, [openModal, store]);
}

export function useDeleteInviteModal({ store }: { store: TOrganizationsMST }): (invitationId: string) => void {
  const { openModal, closeModal } = useModalActions();
  const orgId = useCurrentUserId();

  const onClose = (): void => {
    closeModal({ id: DELETE_INVITE_MODAL_ID });
  };

  return useCallback((invitationId: string) => {
    openModal({
      id: DELETE_INVITE_MODAL_ID,
      title: '',
      content: (
        <DeleteModal
          title="Delete Invitation?"
          message="Are you sure you want to cancel invitation?"
          onDelete={() => store.deleteInvitation({ orgId, invitationId })}
          onClose={onClose}
        />
      ),
      makeActions: () => null,
    });
  }, []);
}

export function useRemoveMemberModal({ store }: { store: TOrganizationsMST }): (memberId: string) => void {
  const { openModal, closeModal } = useModalActions();
  const orgId = useCurrentUserId();

  const getMemberName = (memberId: string): string =>
    pipe(
      store.listMembers,
      either.fold(
        () => '',
        (members) => members.find((member) => member.user_id === memberId)?.name || '',
      ),
    );

  const onClose = (): void => {
    closeModal({ id: REMOVE_MEMBER_MODAL_ID });
  };

  return useCallback((memberId: string) => {
    const memberName = getMemberName(memberId);

    openModal({
      id: REMOVE_MEMBER_MODAL_ID,
      title: '',
      content: (
        <DeleteModal
          title={`Remove member ${memberName}?`}
          message={`Are you sure you want to remove member ${memberName}?`}
          onDelete={() => store.removeMember({ orgId, memberId })}
          onClose={onClose}
        />
      ),
      makeActions: () => null,
    });
  }, []);
}

export function useDeleteTeam(orgId: string): (teamId: string, onComplete?: () => void) => void {
  const { openModal, closeModal } = useModalActions();
  const queryClient = useQueryClient();

  const onClose = (): void => closeModal({ id: DELETE_TEAM_MODAL_ID });

  return useCallback(
    (teamId: string, onComplete?: () => void) => {
      openModal({
        id: DELETE_TEAM_MODAL_ID,
        title: '',
        content: (
          <DeleteModal
            title="Delete Team?"
            message="Are you sure you want to delete the team?"
            onDelete={pipe(
              apiEffects.deleteTeamTE(orgId, { ids: [teamId] }),
              taskEither.mapLeft((x) => {
                cogoToast.error(x?.props?.reason || 'There was an error deleting team.', { heading: 'Error' });
                onClose();
                return x;
              }),
              taskEither.map((x) => {
                cogoToast.success(`Team deleted successfully!`);
                onClose();
                queryClient.invalidateQueries([queryKeys.Teams, { orgId }]);
                if (onComplete) onComplete();
                return x;
              }),
            )}
            onClose={onClose}
          />
        ),
        makeActions: () => null,
      });
    },
    [orgId, openModal, closeModal],
  );
}

export function useOrganizationInvitesList() {
  const { authData } = useAuthState();

  const { data: orgInvitesListE } = useQueryTE(
    [queryKeys.UserOrganizationInvitations, { userId: authData?.user_id }],
    apiEffects.listUserOrgInvitationsTE(authData?.user_id),
    { enabled: Boolean(authData?.is_email_verified) },
  );

  return pipe(
    orgInvitesListE,
    either.getOrElse(() => [] as CF.API.Organizations.OrgMemberInvite[]),
  );
}
