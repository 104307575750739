import { IconCloseSquare } from 'components/Icons/IconCloseSquare';
import { cx } from '@linaria/core';
import { ReactNode } from 'react';
import { closeButton, dialogHeading } from './dialogComponents.styles';

export const dialogComponents = {
  CloseButton: ({ onClick, className }: { onClick: React.MouseEventHandler; className?: string }) => (
    <button className={cx(closeButton, className)} type="button" onClick={onClick} data-testid="dialog-closebtn">
      <IconCloseSquare size={18} color="#282636" />
    </button>
  ),
  Heading: ({ children }: { children: ReactNode }) => <h3 className={dialogHeading}>{children}</h3>,
};
