import { useEffect, useContext, useState } from 'react';
import { either } from 'fp-ts';
import { errorToReactLeft, noop } from 'utils/fp';
import { InputsJobMonitorContext } from 'hocs/InputsJobMonitor/InputsJobMonitor';
import { useQueryClient } from 'react-query';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { getInputAddJobTE } from 'api/inputJobs/getInputAddJob';
import { UploadJobType } from 'hooks/useUploader';
import pluralize from 'pluralize';
import isEmpty from 'lodash/isEmpty';
import { StatusCode } from 'clarifai-web-grpc/proto/clarifai/api/status/status_code_pb';
import { boxSpinnerEl, itemContainer, itemTextContainer } from './JobMonitorExpanded.styles';

type JobMonitorItemProps = {
  job: UploadJobType;
};

export const JobMonitorItem = ({ job }: JobMonitorItemProps): JSX.Element => {
  const { updateUploadJob } = useContext(InputsJobMonitorContext);
  const queryClient = useQueryClient();
  const { jobId, appId, userOrOrgId: userId, finished, uploadedCount, inputsCount, error, percentage, failedCount } = job;
  const [uploadProgress, setUploadProgress] = useState<string>('');

  const requestEnabled = !error && !finished && uploadedCount === inputsCount;

  const { data } = useQueryTE(['getInputAddJob', { userId, appId, jobId }], getInputAddJobTE({ userId, appId, jobId }, errorToReactLeft), {
    refetchInterval: 1000,
    enabled: requestEnabled,
  });

  useEffect(() => {
    either.fold<ReactLeft, CF.API.Inputs.InputAddJobResponse, void>(
      () => noop,
      (r: CF.API.Inputs.InputAddJobResponse) => {
        const jobProgress = r?.inputs_add_job?.progress;
        const successCount = Number(jobProgress?.success_count || 0);
        // only valid for zip files
        let failedArchivesCount;
        const extractionJob = r.inputs_add_job?.extraction_jobs?.[0];
        if (extractionJob?.status?.code === StatusCode.JOB_FAILED) {
          failedArchivesCount = Number(extractionJob?.progress?.failed_archives_count) || 1;
          updateUploadJob(jobId, { failedCount: failedArchivesCount });
        }

        if (successCount === inputsCount) {
          updateUploadJob(jobId, { successCount });
        }

        const isProgressEmpty = isEmpty(jobProgress);
        const inProgressCount = jobProgress?.in_progress_count;
        const pendingCount = jobProgress?.pending_count;
        const inProgressArchivesCount = extractionJob ? Number(extractionJob.progress?.in_progress_archives_count || 0) : 0;
        const isJobFinished = (isProgressEmpty && !inProgressArchivesCount) || (!inProgressCount && !pendingCount && !inProgressArchivesCount);

        if (isJobFinished || failedArchivesCount) {
          updateUploadJob(jobId, { finished: true });
          queryClient.invalidateQueries([queryKeys.ListInputAddJobs, { userId, appId }]);
        }

        return r;
      },
    )(data);
  }, [data, jobId, userId, appId]);

  // reset upload progress when job id changes

  useEffect(() => {
    setUploadProgress('');
  }, [jobId]);

  // update upload progress

  useEffect(() => {
    setUploadProgress(`Uploading (${percentage}%)`);

    if (uploadedCount && inputsCount && uploadedCount === inputsCount && uploadedCount !== failedCount) {
      // wait for 1 second before showing processing
      setTimeout(() => {
        setUploadProgress('Processing...');
      }, 1000);
    }
    if (uploadedCount && inputsCount && uploadedCount === failedCount) {
      setUploadProgress('Error...');
    }
  }, [uploadedCount, inputsCount, percentage, finished, failedCount]);

  return (
    <div className={itemContainer}>
      <div className={itemTextContainer}>
        <div className="title">
          {inputsCount || 0} {pluralize('File', inputsCount)}
        </div>
        <div className="desc">
          {finished && failedCount === 0 ? (
            'Complete'
          ) : (
            <>
              {uploadedCount !== failedCount && <div className={boxSpinnerEl} />}
              <span>{uploadProgress}</span>
              {failedCount > 0 ? <div className="failed">{`Failed ${failedCount} inputs`}</div> : null}
            </>
          )}
        </div>
      </div>
      {/* KEEP IT FOR A SECOND ITEARATION OF IMPLEMENTATION */}
      {/* <div className={jobActionContainer}>
        <IconDotsMenu size={18} color="#808080" />
      </div> */}
    </div>
  );
};
