import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const updateAppTE = <E = Error>(
  {
    userId,
    body,
  }: { userId: string; body: { app: Partial<CF.API.Apps.App> & { id: string }; action: 'merge' | 'overwrite' | 'remove'; reindex: boolean } },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Apps.FetchAppResponse> =>
  fetchTE<CF.API.Apps.FetchAppResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${body.app.id}`,
      method: 'PATCH',
      body,
    },
    onRejected,
  );
