import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const validateIdTE = <E = Error | CF.API.Validate.ValidateResponse>(
  {
    body,
  }: {
    body: {
      validations: [
        {
          object_type: 'model' | 'workflow' | 'app' | 'user' | 'module';
          user_id: string;
          app_id?: string;
          object_id: string;
        },
      ];
    };
  },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Validate.ValidateResponse> =>
  fetchTE<CF.API.Validate.ValidateResponse, E>(
    {
      path: `/api/_api/v2/validate_id`,
      method: 'POST',
      body,
    },
    onRejected,
  );
