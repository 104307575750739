import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

type Res = CF.API.Workflows.ListWorkflowVersions.Response;

export const listWorkflowVersionsTE = <E = Error>(
  { userId, appId, workflowId, params = '' }: { workflowId: string; appId: string; userId: string; params?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, Res> =>
  fetchTE<Res, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/workflows/${encodeURIComponent(workflowId)}/versions${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );
