import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteAppTE = <E = Error>(
  { userId, appId, params = '' }: { userId: string; appId: string; params?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Status> =>
  fetchTE<CF.API.Status, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}${params ? `?${params}` : ''}`,
      method: 'DELETE',
    },
    onRejected,
  );
