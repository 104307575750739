import cogoToast from 'cogo-toast';
import { GRPCClient } from 'api/grpc/client';
import { InputsUpload, Upload, UploadContentPart } from 'clarifai-web-grpc/proto/clarifai/api/resources_pb';
import { mapOr } from 'utils/fp';
import { makeUID } from 'utils/strings/strings';

type GetUploadIds = {
  client: GRPCClient;
  userOrOrgId: string;
  appId: string;
  inputsUpload: InputsUpload;
};

interface ArchiveUploadInitParams {
  client: GRPCClient;
  userOrOrgId: string;
  appId: string;
  file: File;
  patKey: string;
}

interface UploadChunkParams {
  client: GRPCClient;
  userOrOrgId: string;
  appId: string;
  uploadId: string;
  chunk: Uint8Array;
  partNumber: number;
  start: number;
}

interface ArchiveUploadInitResult {
  jobId: string;
  uploadId: string | null;
}

export async function getFileChunks(file: File, sliceSize: number) {
  const chunks = [];
  let partNumber = 1;

  for (let start = 0; start < file.size; start += sliceSize, partNumber += 1) {
    const end = Math.min(start + sliceSize, file.size);
    const blobChunk = file.slice(start, end);
    const chunk = await blobChunk.arrayBuffer();
    chunks.push({ chunk: new Uint8Array(chunk), start, partNumber });
  }
  return chunks;
}

export const getUploadId = async ({ client, userOrOrgId, appId, inputsUpload }: GetUploadIds): Promise<string | null> => {
  const response = await client.postInputsUpload({ userOrOrgId, appId, inputsUpload });
  return mapOr(
    response,
    (resp) => resp.getInputsAddJobsList().flatMap((job) => job.getUploadsList().map((uploadJob) => uploadJob.getId()))[0] || null,
    null,
  );
};

export const initializeUploadJob = async ({
  client,
  userOrOrgId,
  appId,
  file,
  patKey,
}: ArchiveUploadInitParams): Promise<ArchiveUploadInitResult> => {
  const jobId = makeUID(16);
  const uploadObj = new Upload();
  uploadObj.setContentName(file.name);
  uploadObj.setContentLength(file.size);

  const inputsUpload = new InputsUpload();
  inputsUpload.setAppPat(patKey);
  inputsUpload.setInputsAddJobId(jobId);
  inputsUpload.setUpload(uploadObj);
  // create upload job and get the uploadIds list
  const uploadId = await getUploadId({ client, userOrOrgId, appId, inputsUpload });
  return { uploadId, jobId };
};

const prepareContentPart = (chunk: Uint8Array, rangeStart: number, partNumber: number) => {
  const contentPart = new UploadContentPart();
  contentPart.setRangeStart(rangeStart);
  contentPart.setPartNumber(partNumber);
  contentPart.setData(chunk);
  return contentPart;
};

export const uploadChunk = async ({ client, userOrOrgId, appId, uploadId, chunk, partNumber, start }: UploadChunkParams): Promise<number | null> => {
  const contentPart = prepareContentPart(chunk, start, partNumber);
  const resp = await client.putUploadContentPart({ userOrOrgId, appId, uploadId, contentPart });
  const percentage = mapOr(resp, (r) => r?.getUpload()?.getStatus()?.getPercentCompleted() || 0, null);
  if (percentage === null) cogoToast.error('Error while uploading file...');
  return percentage;
};
