import React, { useState, useCallback, useEffect, useRef } from 'react';
import { IconBack, IconCloseRound } from 'components/Icons';
import { Button } from 'components/Button';
import { cx } from '@linaria/core';
import { useSubmitOutside } from 'utils/formik/helpers';
import { withAuth } from 'hocs/withAuth/withAuth';
import { useAuthState } from 'modules/Auth/AuthContext';
import { useModalActions } from 'components/ModalManager/Context';
import { noop } from 'utils/fp';
import { useRouter } from 'next/router';
import { CreateApplication } from './CreateApplication';
import { AppTemplatesView } from './AppTemplatesView';
import { AppTypeSelector } from './AppTypeSelector';
import { useAnimatedViews } from './useAnimatedViews';
import { AppFormIconArea } from './AppFormIconArea';
import { createAppContainer } from './styles';
import { ClosePromptModal } from './ClosePromptModal';
import {
  container,
  mobileBtns,
  showOnMobile,
  hideOnMobile,
  progressBar,
  headerDiv,
  backButton,
  animatedViewsDiv,
  mobileTitle,
  appOverviewContainer,
  chooseTemplateContainer,
  mobileButton,
  desktopButton,
} from './CreateApplicationModal.styles';
import { AppOverview } from './AppOverview';
import { DuplicateApplicationForm } from './DuplicateApplicationForm';

export interface CreateApplicationModalProps {
  closeAction: (isCanceled?: boolean) => void;
  onAppCreated: (createdApp: CF.API.Apps.App) => void;
  onAppDuplicated: (createdApp: CF.API.Apps.DuplicatedApp) => void;
}

export type appStepType = 'appType' | 'createApp' | 'duplicateApp' | 'chooseTemplate' | 'viewApp';
const APP_STEPS_VIEW_IDS = ['appType', 'createApp', 'chooseTemplate', 'viewApp', 'duplicateApp'];
const APP_STEPS_VIEW_STYLES = {
  classNames: {
    mountedStyle: 'mountedStyle',
    unmountedStyle: 'unmountedStyle',
    mountedBackStyle: 'mountedBackStyle',
    unmountedBackStyle: 'unmountedBackStyle',
  },
};

const CLOSE_PROMPT_MODAL_ID = 'CLOSE_PROMPT_MODAL_ID';

const CreateApplicationModal_: React.FC<CreateApplicationModalProps> = ({ closeAction, onAppCreated, onAppDuplicated }) => {
  const router = useRouter();
  const stepRef = useRef<string[]>(['appType']);
  const stepParamsRef = useRef<Record<string, string>>({});
  const { openModal, closeModal } = useModalActions();
  const [animatedState, { goBack, goToView, afterAnimation }] = useAnimatedViews(APP_STEPS_VIEW_IDS, APP_STEPS_VIEW_STYLES);

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      const parsedUrl = new URL(`${document.location.origin}${url}`);
      const steps = parsedUrl.searchParams.getAll('steps').reduce(
        (acc, step) => {
          if (!acc.includes(step)) {
            acc.push(step);
          }
          return acc;
        },
        ['appType'] as string[],
      );

      ['viewAppId', 'viewAppUserOrOrgId'].forEach((param) => {
        const paramValue = parsedUrl.searchParams.get(param);
        if (paramValue) {
          stepParamsRef.current[param] = paramValue;
        }
      });

      if (stepRef.current.length > steps.length) {
        stepRef.current = steps.length > 0 ? [...steps] : ['appType'];
        goBack();
      } else if (steps.length > stepRef.current.length) {
        stepRef.current = [...steps];
        goToView(steps[steps.length - 1]);
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [goBack, goToView]);

  useEffect(() => {
    const run = async (routerQuery: Record<string, string | string[] | undefined>) => {
      const routerQuerySteps = Array.isArray(routerQuery.steps) ? routerQuery.steps : [routerQuery.steps];
      const steps = routerQuerySteps.reduce(
        (acc, step) => {
          if (step && !acc.includes(step)) {
            acc.push(step);
          }
          return acc;
        },
        ['appType'] as string[],
      );

      if (steps.length > stepRef.current.length) {
        const allSteps = [steps[0]];
        router.replace(
          {
            query: {
              ...routerQuery,
              steps: allSteps,
            },
          },
          undefined,
          { shallow: true },
        );
        // eslint-disable-next-line unicorn/no-for-loop
        for (let i = 1; i < steps.length; i += 1) {
          allSteps.push(steps[i]);

          await router.push(
            {
              query: {
                ...routerQuery,
                steps: allSteps,
              },
            },
            undefined,
            { shallow: true },
          );
        }
      }
    };
    if (router.isReady) {
      const routerQuery = { ...router.query };
      run(routerQuery);
    }
  }, []);

  const goBackClbck = useCallback(() => {
    if (router.isReady) {
      router.back();
    }
  }, [goBack]);

  const goToViewClbk = useCallback(
    (viewId: string, params: Record<string, string> = {}) => {
      if (router.isReady) {
        router.push(
          {
            query: {
              ...router.query,
              steps: [...stepRef.current, viewId],
              ...params,
            },
          },
          undefined,
          { shallow: true },
        );
      }
    },
    [goToView],
  );

  const [isLoaded, setIsLoaded] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [formRef, handleSubmitThroughRef] = useSubmitOutside();

  const { authData } = useAuthState();
  const isLoggedIn = Boolean(authData);
  useEffect(() => {
    if (!isLoggedIn) {
      router.push(
        {
          query: {
            ...router.query,
            steps: [],
          },
        },
        undefined,
        { shallow: true },
      );
      closeAction();
    }
  }, [isLoggedIn]);

  const closePromptModal = useCallback(
    (closeFlag: boolean) => {
      closeModal({ id: CLOSE_PROMPT_MODAL_ID });
      if (closeFlag) {
        router.replace(
          {
            query: {
              ...router.query,
              viewAppId: [],
              viewAppUserOrOrgId: [],
              steps: [],
            },
          },
          undefined,
          { shallow: true },
        );
        closeAction(true);
      }
    },
    [closeAction],
  );

  const handleCancelClick = useCallback(() => {
    openModal({
      id: CLOSE_PROMPT_MODAL_ID,
      title: '',
      content: <ClosePromptModal closeAction={closePromptModal} />,
      makeActions: noop,
    });
  }, [closePromptModal]);

  return (
    <div className={container}>
      <div className={cx(hideOnMobile, progressBar)}>
        <div
          className="app-creation-progress-bar"
          style={{
            width: animatedState.step === 'createApp' ? '100%' : `${(animatedState.prevSteps.length + 1) * 25}%`,
          }}
        />
      </div>
      <div className={headerDiv}>
        <div className="header-title">
          {animatedState.prevSteps.length > 0 && (
            <Button className={backButton} variant="tertiary" onClick={() => goBackClbck()}>
              <IconBack size={14} color="#333" />
            </Button>
          )}
          <div className={hideOnMobile}>
            {animatedState.step === 'appType' && 'Guided Tour: Create an App'}
            {animatedState.step === 'chooseTemplate' && 'Select a template to create your app'}
            {animatedState.step === 'createApp' && 'Setup Your App'}
            {animatedState.step === 'duplicateApp' && 'Setup Your App'}
            {animatedState.step === 'viewApp' && 'App name'}
          </div>
        </div>
        <div className="button-div">
          {animatedState.step === 'createApp' && (
            <Button
              variant="primary"
              className={cx(desktopButton, hideOnMobile)}
              id="create-application-submit"
              data-testid="Confirm"
              disabled={!isLoaded || submitDisabled}
              onClick={handleSubmitThroughRef}
            >
              Create App
            </Button>
          )}
          {animatedState.step === 'duplicateApp' && (
            <Button
              variant="primary"
              className={cx(desktopButton, hideOnMobile)}
              id="duplicate-application-submit"
              data-testid="Confirm"
              disabled={!isLoaded || submitDisabled}
              onClick={handleSubmitThroughRef}
            >
              Create App
            </Button>
          )}
          {animatedState.step === 'viewApp' && (
            <Button
              variant="primary"
              className={cx('bold', desktopButton, hideOnMobile)}
              id="select-templte-submit"
              onClick={() => goToViewClbk('duplicateApp')}
            >
              Select Template
            </Button>
          )}

          <Button variant="tertiary" className="close-button" onClick={handleCancelClick}>
            <IconCloseRound size={24} />
          </Button>
        </div>
      </div>
      <div className={cx(showOnMobile, progressBar)}>
        <div
          className="app-creation-progress-bar"
          style={{
            width: animatedState.step === 'createApp' ? '100%' : `${(animatedState.prevSteps.length + 1) * 25}%`,
          }}
        />
      </div>
      <div className={cx(showOnMobile, mobileTitle)}>
        {animatedState.step === 'appType' && 'Guided Tour: Create an App'}
        {animatedState.step === 'chooseTemplate' && 'Select a template to create your app'}
        {animatedState.step === 'createApp' && 'Setup Your App'}
        {animatedState.step === 'viewApp' && 'App name'}
        {animatedState.step === 'duplicateApp' && 'Setup Your App'}
      </div>
      <div className={animatedViewsDiv}>
        {animatedState.mounted.createApp && (
          <div
            className={cx(animatedState.classNames.createApp, appOverviewContainer)}
            onAnimationEnd={(params) => {
              afterAnimation('createApp', params.animationName);
            }}
          >
            <CreateApplication
              setIsLoaded={setIsLoaded}
              setSubmitDisabled={setSubmitDisabled}
              ref={formRef}
              closeAction={closeAction}
              onAppCreated={onAppCreated}
            />
          </div>
        )}
        {animatedState.mounted.appType && (
          <div
            className={cx(animatedState.classNames.appType, appOverviewContainer)}
            onAnimationEnd={(params) => {
              afterAnimation('appType', params.animationName);
            }}
          >
            <AppTypeSelector onTypeSelected={(step: string) => goToViewClbk(step)} />
          </div>
        )}
        {animatedState.mounted.chooseTemplate && (
          <div
            className={cx(animatedState.classNames.chooseTemplate, appOverviewContainer, chooseTemplateContainer)}
            onAnimationEnd={(params) => {
              afterAnimation('chooseTemplate', params.animationName);
            }}
          >
            <AppTemplatesView
              onAppSelected={({ appId, userOrOrgId }) => {
                goToViewClbk('viewApp', { viewAppId: appId, viewAppUserOrOrgId: userOrOrgId });
              }}
            />
          </div>
        )}
        {animatedState.mounted.viewApp && (
          <div
            className={cx(animatedState.classNames.viewApp, appOverviewContainer)}
            onAnimationEnd={(params) => {
              afterAnimation('viewApp', params.animationName);
            }}
          >
            {stepParamsRef.current?.viewAppId && stepParamsRef.current?.viewAppUserOrOrgId && (
              <AppOverview appId={stepParamsRef.current?.viewAppId} userOrOrgId={stepParamsRef.current?.viewAppUserOrOrgId} hideResourcesLink />
            )}
          </div>
        )}
        {animatedState.mounted.duplicateApp && (
          <div
            className={cx(animatedState.classNames.duplicateApp, appOverviewContainer)}
            onAnimationEnd={(params) => {
              afterAnimation('duplicateApp', params.animationName);
            }}
          >
            {stepParamsRef.current?.viewAppId && stepParamsRef.current?.viewAppUserOrOrgId && (
              <div className={createAppContainer}>
                <DuplicateApplicationForm
                  appId={stepParamsRef.current?.viewAppId}
                  userOrOrgId={stepParamsRef.current?.viewAppUserOrOrgId}
                  setIsLoaded={setIsLoaded}
                  setSubmitDisabled={setSubmitDisabled}
                  ref={formRef}
                  onAppDuplicated={onAppDuplicated}
                />
                <AppFormIconArea />
              </div>
            )}
          </div>
        )}
      </div>
      <div className={cx(showOnMobile, mobileBtns)}>
        {(animatedState.mounted.createApp || animatedState.mounted.duplicateApp) && (
          <Button
            variant="primary"
            className={mobileButton}
            id="create-application-submit"
            data-testid="Confirm"
            disabled={!isLoaded || submitDisabled}
            onClick={handleSubmitThroughRef}
          >
            Create App
          </Button>
        )}
        {animatedState.mounted.viewApp && (
          <Button variant="primary" className={mobileButton} id="select-templte-submit" onClick={() => goToViewClbk('duplicateApp')}>
            Select Template
          </Button>
        )}
      </div>
    </div>
    //
  );
};

export const CreateApplicationModal = withAuth<{
  closeAction: (isCanceled?: boolean) => void;
  onAppCreated: (createdApp: CF.API.Apps.App) => void;
  onAppDuplicated: (createdApp: CF.API.Apps.DuplicatedApp) => void;
}>({
  hideFor: 'GUEST',
})(CreateApplicationModal_);
