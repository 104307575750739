import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const listAppInstalledModuleVersionsTE = <E = Error>(
  { appId, userId, sessionToken, apiKey }: { appId?: string; userId?: string; sessionToken?: string; apiKey?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Modules.ListInstalledModuleVersionsResponse> =>
  fetchTE<CF.API.Modules.ListInstalledModuleVersionsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/installed_module_versions`,
      method: 'GET',
      sessionToken,
      apiKey,
    },
    onRejected,
  );

export type postAppInstalledModuleVersionsKeyProps = { appId?: string; userId?: string; sessionToken?: string; installedModuleVersionId?: string };
export const postAppInstalledModuleVersionsKeyTE = <E = Error>(
  { appId, userId, sessionToken, installedModuleVersionId }: postAppInstalledModuleVersionsKeyProps,
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Modules.PostInstalledModuleVersionsKeyResponse> =>
  fetchTE<CF.API.Modules.PostInstalledModuleVersionsKeyResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/installed_module_versions/${installedModuleVersionId}/key`,
      method: 'POST',
      sessionToken,
      body: {},
    },
    onRejected,
  );
