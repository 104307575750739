import { useRouter } from 'next/router';

type UrlQueryParams = {
  userOrOrgId: string;
  appId: string;
  inputId: string;
};

export const useBaseReqParams = (): UrlQueryParams => {
  const router = useRouter();

  if (!router || !router.isReady) {
    return {
      userOrOrgId: '',
      appId: '',
      inputId: '',
    };
  }

  return {
    userOrOrgId: router.query.userOrOrgId,
    appId: router.query.appId,
    inputId: router.query.inputId,
  } as UrlQueryParams;
};
