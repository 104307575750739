import { either, task } from 'fp-ts';
import { Either } from 'fp-ts/lib/Either';
import { pipe as fPipe } from 'fp-ts/lib/function';
import { ErrorType, getErrorUiStateKey } from 'utils/uiStates/helpers';
import { ReactLeft } from './uiStates/uiStates';

export const isString = (a: unknown): boolean => typeof a === 'string' || a instanceof String;
export const noop = (..._a: unknown[]): null | void => {
  return null;
};

// exporting like this for vscode auto-import to work with `pipe`
export const pipe = fPipe;

/**
 * Convenience function for mapping an `Either<L, R>` to some value of type `V`
 * if it's an `R`, or some default value of type `V`.
 *
 * @param eitherV the Either value that we want to map
 * @param mapFn the mapping function that is applied if `eitherV` is a "Right"
 * @param orValue the value to use if `eitherV` is a Left.
 * @returns If `eitherV` is a Left, returns `orValue`, otherwise, returns `map(eitherV.__right)`
 */
export function mapOr<L, R, V>(eitherV: Either<L, R>, mapFn: (right: R) => V, orValue: V): V {
  return pipe(
    eitherV,
    either.match(
      (_left: L) => orValue,
      (right: R) => mapFn(right),
    ),
  );
}

/**
 * Applies a condition to a Right; if it matches, it turns it into a Left.
 * Common use case is getting a successful response from API; but it being an empty array.
 * In such a case, we can use this util to denote the emptiness in Left explicitly.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function makeLeftEncoder<Left, Right>(isLeft: (a: Right) => boolean, leftValue: Left) {
  return (responseE: Either<Left, Right>) =>
    pipe(
      responseE,
      // either.map((response) => response.outputs[0]),
      either.chain((value) => {
        // chain un-nests inner returned Either
        // we check the "Right" data and see if it's empty
        // if empty, we return a Left('EMPTY')
        if (isLeft(value)) {
          return either.left(leftValue);
        }
        // if not empty, we return Right(ModelOutput)
        // together, we get Left | Right === Either :)
        return either.right(value);
        // if we didn't do .chain, and instead used .map; the output
        // after this step would have been Right(Right(Value))

        // notice how in either.map, we return "normal" data, not a left/right
      }),
    );
}

export type RequestErrors = Error | { status: CF.API.Status };

export const errorToReactLeft = <T extends RequestErrors>(errorResponse: T): ReactLeft<{ title: string; reason: string; errorResponse: T }> => ({
  type: getErrorUiStateKey(errorResponse as ErrorType),
  props: (() => {
    // if Clarifai API error response
    if ('status' in errorResponse && errorResponse.status.description) {
      return {
        title: errorResponse.status.description || 'Error',
        reason: errorResponse.status.details?.slice(0, 200) || '',
        errorResponse,
      };
    }

    // if another type of error like network etc.
    return {
      title: 'Error',
      reason: (errorResponse as Error).message || '',
      errorResponse,
    };
  })(),
});

export const noopTask: task.Task<null> = task.of(null);

export { either, task };
