import { cx } from '@linaria/core';
import { Pill } from 'components/Pill';
import { Link } from 'components/Link';
import { NextRouter, useRouter } from 'next/router';
import React, { cloneElement, ReactNode, useMemo, useState, useEffect } from 'react';
import { Tooltip } from 'components/Tooltip';
import { IconLock } from 'components/Icons';
import { useIsSSR } from '@react-aria/ssr';
import { textSmallBold } from 'styles/typography';
import { IconProps } from 'components/Icons/IconProps';
import { menuItemWrapper, menuGroup, tooltipTitle, Divider, menuStack } from './Menu.styles';
import { listWithGap } from '../../Sidebar.styles';

export enum Version {
  ALPHA = 'ALPHA',
  BETA = 'BETA',
  NEW = 'NEW',
}

interface MenuItemProps {
  icon?: React.FC<IconProps>;
  count?: number;
  children?: ReactNode;
  href?: string;
  title?: string;
  onClick?: () => void;
  comingSoon?: boolean;
  version?: Version;
  className?: string;
  isCollapsed?: boolean;
}

interface Legacy_MenuItemProps {
  icon?: JSX.Element | null;
  count?: number;
  children?: ReactNode;
  href?: string;
  title?: string;
  onClick?: () => void;
  comingSoon?: boolean;
  version?: Version;
  className?: string;
  isCollapsed?: boolean;
}

interface MenuGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  title?: string;
  withDivider?: boolean;
}

interface MenuStackProps extends React.HTMLAttributes<HTMLElement> {
  children: ReactNode;
  withDivider?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  title,
  icon: Icon,
  count = 0,
  version,
  children,
  onClick,
  href,
  comingSoon,
  className,
  isCollapsed = false,
}) => {
  const router = useRouter();
  const active = menuItemIsActive(router, href || '#');
  const accessory = useMemo(() => {
    if (comingSoon) {
      return renderLockIcon();
    }
    if (version) {
      return renderVersionTag(version);
    }

    return renderCountPill(count || 0, active);
  }, [comingSoon, count, active, version]);

  const menuContentStyle = {
    ...(comingSoon ? { opacity: '0.6' } : {}),
  };

  // THIS LOGIC WAS ADDED BECAUSE WE HAD WEIRD SVG APPERANCE BEHAVIOUR
  const isSSR = useIsSSR();
  const [iconKey, setIconKey] = useState(`MenuItemKey_${href}_${Math.random()}`);

  useEffect(() => {
    setIconKey(`MenuItemKey_${href}_${Math.random()}`);
  }, [isSSR]);

  return (
    <LinkWrapper comingSoon={comingSoon} href={href} onClick={onClick}>
      <TooltipWrapper title={Boolean(Icon) && isCollapsed && title}>
        <div className={cx(menuItemWrapper, active && 'active', !href && !onClick && 'noHover', className)}>
          {Icon && cloneElement(<Icon size={22} />, { key: iconKey, style: { opacity: comingSoon ? '0.6' : '1' } })}
          {!isCollapsed &&
            (children ? (
              <div className="menu-content" style={menuContentStyle}>
                {children}
              </div>
            ) : (
              <div className="menu-content" style={menuContentStyle}>
                {title}
              </div>
            ))}
          {accessory && <div>{accessory}</div>}
        </div>
      </TooltipWrapper>
    </LinkWrapper>
  );
};

export const Legacy_MenuItem: React.FC<Legacy_MenuItemProps> = ({
  title,
  icon,
  count,
  version,
  children,
  onClick,
  href,
  comingSoon,
  className,
  isCollapsed = false,
}) => {
  const router = useRouter();
  const active = menuItemIsActive(router, href || '#');
  const accessory = useMemo(() => {
    if (comingSoon) {
      return renderLockIcon();
    }
    if (version) {
      return renderVersionTag(version);
    }

    return renderCountPill(count || 0, active);
  }, [comingSoon, count, active, version]);

  const menuContentStyle = {
    ...(comingSoon ? { opacity: '0.6' } : {}),
  };

  // THIS LOGIC WAS ADDED BECAUSE WE HAD WEIRD SVG APPERANCE BEHAVIOUR
  const isSSR = useIsSSR();
  const [iconKey, setIconKey] = useState(`MenuItemKey_${href}_${Math.random()}`);

  useEffect(() => {
    setIconKey(`MenuItemKey_${href}_${Math.random()}`);
  }, [isSSR]);

  return (
    <LinkWrapper comingSoon={comingSoon} href={href} onClick={onClick}>
      <TooltipWrapper title={Boolean(icon) && isCollapsed && title}>
        <div className={cx(menuItemWrapper, active && 'active', !href && !onClick && 'noHover', className)}>
          {icon && cloneElement(icon, { key: iconKey, style: { opacity: comingSoon ? '0.6' : '1' } })}
          {!isCollapsed &&
            (children ? (
              <div className="menu-content" style={menuContentStyle}>
                {children}
              </div>
            ) : (
              <div className="menu-content" style={menuContentStyle}>
                {title}
              </div>
            ))}
          {accessory && <div>{accessory}</div>}
        </div>
      </TooltipWrapper>
    </LinkWrapper>
  );
};

export const MenuGroup: React.FC<MenuGroupProps> = ({ children, title, withDivider, ...rest }) => {
  return (
    <div className={cx(menuGroup, 'menu-group', rest.className)} {...rest}>
      {title && <div className="title">{title}</div>}
      {children}
      {withDivider && <Divider />}
    </div>
  );
};

export const MenuStack: React.FC<MenuStackProps> = ({ children, className, withDivider, ...rest }) => {
  return (
    <section className={cx(menuStack, listWithGap, withDivider && 'divider-visible', 'fullWidth', className)} {...rest}>
      {children}
    </section>
  );
};

const LinkWrapper = ({
  children,
  href,
  onClick,
  comingSoon,
}: React.PropsWithChildren<{ href?: string; comingSoon?: boolean; onClick?: () => void }>): JSX.Element => {
  return href ? (
    <Link href={href} className={cx('fullWidth', comingSoon && 'no-pointer-events')}>
      {children}
    </Link>
  ) : (
    <span
      className={cx('fullWidth', comingSoon && !onClick && 'no-pointer-events')}
      role="button"
      onKeyPress={onClick}
      tabIndex={0}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export const TooltipTitle = ({ title }: { title?: string }): JSX.Element => <div className={tooltipTitle}>{title}</div>;

export const TooltipWrapper: React.FC<{ title?: string | false }> = ({ title, children }): JSX.Element =>
  title ? (
    <Tooltip data={<TooltipTitle title={title} />} style={{ zIndex: 999 }}>
      <div>{children}</div>
    </Tooltip>
  ) : (
    <>{children}</>
  );

function renderLockIcon(): JSX.Element {
  return <IconLock size={20} color="#808080" />;
}

function renderVersionTag(version: Version): JSX.Element {
  return <div className={cx('version', textSmallBold)}>{version.toString().toUpperCase()}</div>;
}

function renderCountPill(count: number, active: boolean): JSX.Element | null {
  return count > 0 ? (
    <Pill style={{ borderRadius: '2rem', fontSize: '0.875rem', fontWeight: 700 }} active={active} data-testid="sidebar-pill">
      {count}
    </Pill>
  ) : null;
}

function menuItemIsActive(router: NextRouter, href: string): boolean {
  const pathWithoutQueryParams = router.asPath?.split('?')[0];
  return pathWithoutQueryParams === href;
}
