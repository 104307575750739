import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const fetchModelTE = <E = Error>(
  { modelId, appId, userId, params = '', sessionToken }: { modelId: string; appId?: string; userId?: string; params?: string; sessionToken?: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Models.FetchModelResponse> =>
  fetchTE<CF.API.Models.FetchModelResponse, E>(
    {
      sessionToken,
      path: userId
        ? `/api/_api/v2/users/${userId}/apps/${appId}/models/${modelId}${params ? `?${params}` : ''}`
        : `/api/_api/v2/models/${modelId}${params ? `?${params}` : ''}`,
      method: 'GET',
    },
    onRejected,
  );
