import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const duplicateApp = <E = Error>(
  {
    userId,
    appId,
    body,
  }: {
    userId?: string;
    appId?: string;
    params?: string;
    body: CF.API.Apps.DuplicateAppBody;
  },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Apps.duplicateMockApp> =>
  fetchTE<CF.API.Apps.duplicateMockApp, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/duplications`,
      method: 'POST',
      body,
    },
    onRejected,
  );
