import { IconChevronRight } from 'components/Icons';
import Badge from '@mui/material/Badge';
import { fontBold } from 'styles/typography';
import { Button } from 'reakit';
import { useRouter } from 'next/router';
import { cx } from '@linaria/core';
import { flex } from 'styles/utils';
import { TextIcon } from 'components/TextIcon';
import { makeInitials, stringToColor } from 'utils/strings/strings';
import { inviteButton, inviteDescription, badgeColor } from './OrgInvitation.styles';
import { avatar } from '../Account/styles';

export const OrgInvitation: React.FC<{ invite: CF.API.Organizations.OrgMemberInvite }> = ({ invite }) => {
  const router = useRouter();

  const goToJoinOrgPage = () => {
    router.push(`/join_org?invite=${invite.id}&from=${encodeURIComponent(router.asPath)}`);
  };

  return (
    <Button data-testid={`org-invitation-${invite.id}`} className={inviteButton} onClick={goToJoinOrgPage}>
      <Badge
        badgeContent={1}
        classes={{ badge: badgeColor }}
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <TextIcon
          text={makeInitials(invite.organization.name)}
          background={stringToColor(invite.organization.name)}
          className={cx('avatar', avatar)}
        />
      </Badge>
      <span className={inviteDescription}>
        You have been invited to join <span className={fontBold}>{invite.organization.name}</span>.
      </span>
      <IconChevronRight size={20} />
    </Button>
  );
};

export const OrgInvitationsList: React.FC<{ orgInvitesList?: CF.API.Organizations.OrgMemberInvite[] }> = ({ orgInvitesList = [] }) => {
  return orgInvitesList ? (
    <ul className={cx(flex.init, flex.direction.column, flex.gap.half)}>
      {orgInvitesList.map((invite) => (
        <li key={invite.id}>
          <OrgInvitation invite={invite} />
        </li>
      ))}
    </ul>
  ) : null;
};
