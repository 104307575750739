import { cx } from '@linaria/core';
import { either, taskEither } from 'fp-ts';
import { useState, useCallback, ChangeEvent, FC, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { Form, Formik } from 'formik';
import { InputField } from 'components/Formik/InputField';
import { SelectField } from 'components/Formik/SelectField';
import cogoToast from 'cogo-toast';
import { useModalActions } from 'components/ModalManager/Context';
import { Button } from 'components/deprecated/Button';
import { Checkbox } from 'components/Checkbox';
import { Row, Col } from 'components/Grid';
import { SelectOption } from 'components/Select/SelectControlled';
import { textBig, textRegularLight } from 'styles/typography';
import { useIdValidation$ } from 'hooks/useIdValidation$';
import { countryCollection, jobRoleCollection, intentionCollection } from 'modules/User/components/Settings/ContactInformation/constants';
import { useAuthActions, useAuthState } from 'modules/Auth/AuthContext';
import { errorToReactLeft, pipe } from 'utils/fp';
import { queryKeys, useMutationTE, useQueryTE } from 'utils/react-query';
import { reactLeftToJSX } from 'utils/uiStates/uiStates';
import { alphabeticalSort } from 'utils/collections';
import { updateUserProfileTE, UserBody } from 'api/users/updateUser';
import { getUserMeTE, updateUserConsentTE } from 'api/users';
import { flex } from 'styles/utils';
import { cssHelpers } from 'styles/utils/core';
import { omit } from 'rambda';
import nProgress from 'nprogress';
import { useCurrentUserId } from 'modules/Auth/hooks';
import { object, string } from 'yup';
import { errorMsg } from 'components/Formik/Forms.styles';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { HUBSPOT_CUSTOM_EVENTS_NAME, HUBSPOT_FORM_IDS, sendEvent, submitFormApi } from 'utils/hubspotApi/hubspotApi';
import { appStorage } from 'utils/appStorage';
import { useRouter } from 'next/router';
import { wrapper, accountVerifiedBadge, jobRoleSelectWrapper } from './OnboardingDialog.styles';
import { checkboxLabel, textLinkExternal, labelDescription } from '../styles';
export type FormValues = {
  userId: string;
  firstName?: string;
  lastName?: string;
  company?: string;
  country?: SelectOption;
  onboardingJobTitle?: string;
  intention?: SelectOption;
  onboardingJobRole?: SelectOption;
};
type nameErrorType = {
  firstName?: string;
  lastName?: string;
};
type OnboardingDialogProps = {
  user: CF.API.Users.User;
};
const genericStringRules = string().required('Required');
const onBoardingDialogSchema = object().shape({
  userId: genericStringRules,
  company: genericStringRules,
  onboardingJobTitle: genericStringRules,
  // eslint-disable-next-line react/forbid-prop-types
  onboardingJobRole: object({
    value: string().required('Required')
  }),
  // eslint-disable-next-line react/forbid-prop-types
  country: object({
    value: string().required('Required')
  }),
  // eslint-disable-next-line react/forbid-prop-types
  intention: object({
    value: string().required('Required')
  })
});
export const OnboardingDialog_: FC<OnboardingDialogProps> = ({
  user
}) => {
  const isGDPRConsentPending = !user?.date_gdpr_consent || !user?.date_tos_consent;
  const isNameMissing = !user?.first_name || !user?.last_name;
  const [isConsentGranted, setIsConsentGranted] = useState<boolean>(!isGDPRConsentPending);
  // We need to store this value to use it after our API call when all data was updated
  const isGDPRConsentPendingPersisted = useRef(isGDPRConsentPending);
  const queryClient = useQueryClient();
  const userOrOrgId = useCurrentUserId();
  const {
    closeModal
  } = useModalActions();
  const {
    updateUserId
  } = useAuthActions();
  const {
    track
  } = useDataCollection();
  const countries = alphabeticalSort(countryCollection);
  const jobRoles = jobRoleCollection;
  const router = useRouter();
  const [onIdChange, {
    errorString
  }] = useIdValidation$({
    userId: userOrOrgId,
    entityName: 'user'
  });
  const updateContactInfoMutation = useMutationTE((values: UserBody) => pipe(updateUserConsentTE({
    consent: isConsentGranted,
    fields: ['gdpr', 'tos']
  }, errorToReactLeft),
  // return result of previous task; don't fail the whole operation if app creation fails
  // taskEither.chainFirstTaskK(() => createDefaultAppTE),
  taskEither.chain(() => updateUserProfileTE({
    userId: 'me',
    body: {
      info: omit(['user_id'], values.info)
    }
  }, errorToReactLeft)), taskEither.chain(() => updateUserProfileTE({
    userId: 'me',
    body: {
      info: {
        user_id: values.info?.user_id
      }
    }
  }, errorToReactLeft)))(), {
    onMutate: () => {
      nProgress.start();
    },
    onSettled: () => {
      nProgress.done();
    },
    onSuccess: ({
      user: updatedUser
    }) => {
      cogoToast.success('Contact information updated');
      if (isGDPRConsentPendingPersisted.current) {
        queryClient.refetchQueries(); // we need to refetch all queries if user don't have GDPR or TOC
      } else {
        queryClient.invalidateQueries([queryKeys.LoggedInUser]);
        queryClient.invalidateQueries([queryKeys.Users, {
          userOrOrgId: updatedUser.id
        }]);
      }
      track('CTA', {
        param1: 'onboarding_dialog',
        param2: 'completed'
      });
      appStorage.set('first_app_not_created', 'true');
      submitFormApi({
        formId: HUBSPOT_FORM_IDS.signUpForm,
        fields: [{
          name: 'firstname',
          value: updatedUser.first_name
        }, {
          name: 'lastName',
          value: updatedUser.last_name
        }, {
          name: 'email',
          value: updatedUser.email_addresses[0].email
        }, {
          name: 'jobtitle',
          value: updatedUser.job_title || ''
        }, {
          name: 'job_function',
          value: updatedUser.job_role || ''
        }, {
          name: 'company',
          value: updatedUser.company_name || ''
        }, {
          name: 'intension',
          value: updatedUser.intension || ''
        }]
      });
      sendEvent({
        eventName: HUBSPOT_CUSTOM_EVENTS_NAME.signupCompletedDate,
        email: updatedUser.email_addresses[0].email,
        properties: {}
      });
      sendEvent({
        eventName: HUBSPOT_CUSTOM_EVENTS_NAME.firstVisit,
        email: updatedUser.email_addresses[0].email,
        properties: {}
      });
      updateUserId(updatedUser.id);
      router.push(`/${updatedUser.id}/apps`);
      closeModal({
        id: 'onboarding_dialog'
      });
    },
    onError: ({
      props
    }) => {
      cogoToast.error(props?.reason || 'Could not update the profile', {
        heading: 'Error'
      });
      queryClient.invalidateQueries([queryKeys.LoggedInUser]);
    }
  });
  const handleLegalCheckboxChange = useCallback(e => {
    setIsConsentGranted(e.target.checked);
  }, []);
  const handleUserIdChange = (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void): void => {
    const value = event.target.value;
    onIdChange(value);
    setFieldValue('userId', value);
  };
  const validate = (values: FormValues): nameErrorType => {
    const errors: {
      [key: string]: string;
    } = {};
    if (isNameMissing) {
      if (!values.firstName) {
        errors.firstName = 'Required';
      } else if (!values.lastName) {
        errors.lastName = 'Required';
      }
    }
    return errors;
  };
  return <div className={cx(wrapper, 'fullWidth')}>
      <div className={cx(flex.init, flex.direction.column, flex.alignItems.center, cssHelpers.margin.yb[2])}>
        <img src="/auth_icons/account-verified.svg" alt="sheild icon" className={accountVerifiedBadge} />
        <h1 className={cx(textBig)}>Please complete your profile.</h1>
        <p className={textRegularLight}>In the spirit of community, please tell us more about yourself.</p>
      </div>

      <Formik initialValues={{
      userId: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      company: user.company_name,
      onboardingJobTitle: user.job_title,
      onboardingJobRole: jobRoles.find(j => j.value === user.job_role),
      country: countries.find(c => c.value === user.user_detail?.country)
    }} onSubmit={(values: FormValues) => {
      updateContactInfoMutation.mutate({
        info: {
          user_id: values.userId,
          company_name: values.company,
          country: values.country?.value,
          job_role: values.onboardingJobRole?.value,
          job_title: values.onboardingJobTitle,
          first_name: values.firstName,
          last_name: values.lastName,
          intention: values.intention?.value
        }
      });
    }} validationSchema={onBoardingDialogSchema} validate={validate}>
        {({
        isValid,
        dirty,
        values,
        setFieldValue
      }) => <Form>
            <Row marginBottom={2}>
              <Col xs={12}>
                <div className={cx(labelDescription, cssHelpers.margin.xl.half)}>
                  We suggest you create a memorable User ID to help people find you in community.
                </div>
                <InputField onChange={event => handleUserIdChange(event, setFieldValue)} label="User ID" name="userId" id="userId" placeholder="User ID" required />
                {values.userId && errorString && <p className={errorMsg}>{errorString}</p>}
              </Col>
            </Row>
            {isNameMissing && <Row columnSpacing={2} marginBottom={2}>
                <Col xs={12} sm={6}>
                  <InputField label="First Name" name="firstName" id="firstName" placeholder="First Name" required />
                </Col>
                <Col xs={12} sm={6}>
                  <InputField label="Last Name" name="lastName" id="lastName" placeholder="Last Name" required />
                </Col>
              </Row>}
            <Row columnSpacing={2} marginBottom={2}>
              <Col xs={12} sm={6}>
                <SelectField name="country" label="Country" placeholder="Select your Country" options={countries} required />
              </Col>
              <Col xs={12} sm={6}>
                <InputField label="Company" name="company" id="company" placeholder="Company" required />
              </Col>
            </Row>
            <Row columnSpacing={2} marginBottom={!isGDPRConsentPending ? 2 : undefined}>
              <Col xs={12} sm={6}>
                <InputField label="Job Title" name="onboardingJobTitle" id="onboardingJobTitle" placeholder="Job Title" required />
              </Col>
              <Col xs={12} sm={6}>
                <SelectField menuState={{
              placement: 'top-start'
            }} menuWrapperClass={jobRoleSelectWrapper} name="onboardingJobRole" label="Job Role" placeholder="Select your Job Role" options={jobRoles} required />
              </Col>
            </Row>
            <Row columnSpacing={2} marginBottom={2}>
              <Col xs={12} sm={12}>
                <SelectField name="intention" label="Intention" placeholder="What is your primary goal?" options={intentionCollection} required />
              </Col>
            </Row>
            {isGDPRConsentPending && <Row marginBottom={4}>
                <Col xs={12}>
                  <div className="text-legal">
                    <Checkbox onChange={handleLegalCheckboxChange} checked={isConsentGranted} data-testid="legal-consent" className={cx(checkboxLabel, 'checkbox-label')}>
                      I have read and agree to Clarifai&apos;s
                      <a className={textLinkExternal} rel="noreferrer" target="_blank" href="https://www.clarifai.com/terms">
                        Terms of Service
                      </a>
                      and
                      <a className={textLinkExternal} rel="noreferrer" target="_blank" href="https://www.clarifai.com/privacy-policy">
                        Privacy Policy
                      </a>
                      .
                    </Checkbox>
                  </div>
                </Col>
              </Row>}

            <div className={cx(flex.init, flex.justifyContent.end)}>
              <Button type="submit" kind="primary" className={cx(cssHelpers.margin.yb[1], 'fullWidth')} disabled={isGDPRConsentPending && !isConsentGranted || updateContactInfoMutation.isLoading || !isValid || !dirty || !!errorString}>
                {updateContactInfoMutation.isLoading ? '...please wait...' : 'Complete your profile'}
              </Button>
            </div>
          </Form>}
      </Formik>
    </div>;
};
export const OnboardingDialog = (): JSX.Element => {
  const sessionToken = useAuthState().authData?.session_token;
  const {
    data
  } = useQueryTE([queryKeys.LoggedInUser, {
    sessionToken
  }], getUserMeTE(undefined, errorToReactLeft));
  return pipe(data, either.fold(reactLeftToJSX(), ({
    user
  }) => <OnboardingDialog_ user={user} />));
};
export const OnboardingDialog_testable = {
  OnboardingDialog_
};