import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export type TrainModelBodyType = { model_versions: Array<Partial<CF.API.Models.Model>> };

interface ErrorObject {
  status: {
    code: number;
    description: string;
    details: string;
  };
}

export const trainModelTE = <E = ErrorObject>(
  { userOrOrgId, appId, modelId, body }: { userOrOrgId: string; appId: string; modelId: string; body: TrainModelBodyType },
  onRejected?: (e: ErrorObject) => E,
): TaskEither<E, CF.API.Models.FetchModelResponse> =>
  fetchTE<CF.API.Models.FetchModelResponse, E>(
    {
      path: `/api/_api/v2/users/${userOrOrgId}/apps/${appId}/models/${modelId}/versions`,
      method: 'POST',
      body,
    },
    onRejected,
  );
