import { useRouter } from 'next/router';
import { Link } from 'components/Link';
import { Popover, PopoverGroup, SegmentTitle, TextSmall, PopoverStateReturn } from 'components/Popover';
import { AccountSelector } from 'modules/Organizations/components/AccountSelector';
import React, { useRef } from 'react';
import { useCurrentUserId, useIsOrgAccount } from 'modules/Auth/hooks';
import { Button } from 'components/ui_library/Button';
import { motion } from 'framer-motion';
import { noop } from 'utils/fp';
import { useClickAway } from 'react-use';
import { OrgInvitationsList } from 'modules/Organizations/components/OrgInvitation';
import { useAccountPopoverLinks } from './AccountPopover.hooks';
import { accountPopover, mobileNavbarDropdown } from './styles';

export interface DesktopAccountItemsProps {
  onLogout: React.MouseEventHandler<HTMLButtonElement>;
  closePopup?: () => void;
  orgInvitesList?: CF.API.Organizations.OrgMemberInvite[];
}

export interface MobileAccountItemsProps {
  onLogout: () => void;
  canCreateApp: boolean;
  handleCreateApp: () => void;
  closePopup?: () => void;
  orgInvitesList?: CF.API.Organizations.OrgMemberInvite[];
}

export interface AccountPopoverProps extends Omit<DesktopAccountItemsProps, 'closePopup'> {
  popover: PopoverStateReturn;
}

const PopoverLink: React.FC<{ href: string; text: string }> = ({ href, text }) => (
  <Link href={href}>
    <TextSmall>{text}</TextSmall>
  </Link>
);

const PopoverButton: React.FC<{ onClick?: React.MouseEventHandler<HTMLSpanElement>; text: string }> = ({ onClick, text }) => (
  <TextSmall role="button" onClick={onClick}>
    {text}
  </TextSmall>
);

export const MobileAccountItems: React.FC<MobileAccountItemsProps> = ({
  onLogout,
  closePopup = noop,
  canCreateApp,
  handleCreateApp,
  orgInvitesList,
}) => {
  const path = usePathWithoutQueryParams();
  const isOrg = useIsOrgAccount();
  const userId = useCurrentUserId();
  const contentRef = useRef(null);

  const retractNavbarAndCreateApp = (): void => {
    handleCreateApp();
    closePopup();
  };

  const retractNavbarAndLogout = (): void => {
    onLogout();
    closePopup();
  };

  const linksWithText = [
    { href: `/${userId}/apps`, text: isOrg ? 'Org Apps' : 'My Apps' },
    { href: `/explore`, text: 'Community' },
    { href: isOrg ? '/settings/organization' : '/settings', text: 'Account Setting' },
  ];

  useClickAway(contentRef, closePopup);

  return (
    <div className={mobileNavbarDropdown}>
      <motion.div ref={contentRef} initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ opacity: 0, height: 0 }} className="content">
        <AccountSelector />
        <OrgInvitationsList orgInvitesList={orgInvitesList} />
        <div>
          <div className="list">
            {canCreateApp && (
              <Button variant="unstyled" onClick={retractNavbarAndCreateApp}>
                Create an App
              </Button>
            )}
            {linksWithText.map(({ href, text }) => (
              <Link key={href} href={href} className={path === href ? 'active' : undefined}>
                {text}
              </Link>
            ))}
            <div className="divider" />
            <Button variant="unstyled" onClick={retractNavbarAndLogout}>
              Sign Out
            </Button>
          </div>
        </div>
      </motion.div>

      <button type="button" aria-label="backdrop" className="backdrop" onClick={closePopup} />
    </div>
  );
};

export const DesktopAccountItems: React.FC<DesktopAccountItemsProps> = ({ onLogout, closePopup, orgInvitesList }) => {
  const path = usePathWithoutQueryParams();
  const sections = useAccountPopoverLinks(onLogout);

  return (
    <>
      <PopoverGroup style={{ borderTop: 0, paddingTop: 0 }}>
        <AccountSelector />
        <OrgInvitationsList orgInvitesList={orgInvitesList} />
      </PopoverGroup>
      {sections.map(({ id, links, title }) => {
        return (
          <PopoverGroup key={id}>
            {title && <SegmentTitle>{title}</SegmentTitle>}
            {links.map(({ text, handleClick, url }) => {
              return url && url !== path ? (
                <PopoverLink href={url} text={text} key={`${id}_${url}`} />
              ) : (
                <PopoverButton text={text} onClick={handleClick || closePopup} key={`${id}_${url}`} />
              );
            })}
          </PopoverGroup>
        );
      })}
    </>
  );
};

export const AccountPopover: React.FC<AccountPopoverProps> = ({ popover, onLogout, orgInvitesList }): JSX.Element => {
  const closePopup = (): void => {
    popover.hide();
  };

  return (
    <Popover data-testid="account-popover" aria-label="Account" popover={popover} className={accountPopover}>
      <DesktopAccountItems onLogout={onLogout} closePopup={closePopup} orgInvitesList={orgInvitesList} />
    </Popover>
  );
};

function usePathWithoutQueryParams() {
  const { asPath } = useRouter();
  return asPath?.split('?')[0] || '';
}
