import { css } from '@linaria/core';
import { theming } from 'styles/utils';
export const inputJobsWrapperCollapsed = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-inputJobsWrapperCollapsed-ifz1e0f";
export const inputJobsWrapperExpanded = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-inputJobsWrapperExpanded-i6n7fsh";
export const inputJobsWrapper = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-inputJobsWrapper-ik6cv4i";
export const icon = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-icon-i1ibqhht";
export const boxSpinnerEl = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-boxSpinnerEl-b1x0oia8";
export const jobsContainer = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-jobsContainer-j7lc972";
export const itemContainer = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-itemContainer-i16n5aym";
export const itemTextContainer = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-itemTextContainer-i4aiqvo";
export const jobActionContainer = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-jobActionContainer-j1uwqjut";
export const jobsFooter = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-jobsFooter-j1s2mslr";
export const checkbox = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-checkbox-cbuj77o";
export const checkboxLabel = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-checkboxLabel-cnv8bi7";
export const jobsHeader = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-jobsHeader-j15f6mba";
export const title = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-title-tzrdhql";
export const minimizeIcon = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-minimizeIcon-maigzzb";
export const tabs = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-tabs-t2rylif";
export const tabItem = "odules__InputsJobMonitor__JobMonitorExpanded_styles_ts-tabItem-t1qut7iw";

require("./JobMonitorExpanded.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./JobMonitorExpanded.styles.ts");