if (process.browser) {
  window.labeler?.init(__webpack_share_scopes__.default);
}
;
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Hydrate, QueryClientProvider } from 'react-query';
import { DefaultSeo } from 'next-seo';
import type { OpenGraphMedia } from 'next-seo/lib/types';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import Nprogress from 'nprogress';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import 'styles/global.css';
import 'react-image-crop/dist/ReactCrop.css';
import { createApiErrorSubject } from 'utils/request/clarifaiFetch';
import { AnalyticsCollectionProvider } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { SSRProvider } from '@react-aria/ssr';
import { makeAppQueryClient } from 'utils/apiCache/queryClient';
import { InputsJobMonitorProvider } from 'hocs/InputsJobMonitor/InputsJobMonitor';
import { TipPopoverProvider } from 'components/TipPopover/TipPopoverContext';
import { ReCaptchaContext } from 'modules/ReCaptchaContext';
import { TOAST_CONTAINER_ID } from 'constants/toastMessage';
import { css } from '@linaria/core';
import { theming } from 'styles/utils';
import { FeaturedFlagsProvider } from 'modules/FeaturedFlags/FeaturedFlagsProvider';
import { FeatureFlagsProviderLD } from 'modules/FeatureFlagsLD/FeatureFlagsProviderLD';
import type { RequestWatcherProps } from 'modules/Auth/RequestWatcher';
import { usePageTrack } from '../hooks/usePageTrack';
const RequestWatcher = dynamic<RequestWatcherProps>(import('modules/Auth/RequestWatcher').then(mod => mod.RequestWatcher), {
  ssr: false
});
const ModalManager = dynamic<unknown>(import('components/ModalManager').then(mod => mod.ModalManager));
const AuthProvider = dynamic<{
  initialAuthData?: CF.LocalUserWithOrgUserAndPlan;
  children: ReactNode;
}>(import('modules/Auth/AuthContext').then(mod => mod.AuthProvider));
const ModalProvider = dynamic<{
  animated: boolean;
  children: ReactNode;
}>(import('components/ModalManager/Context').then(mod => mod.ModalProvider));
Nprogress.configure({
  showSpinner: false
});
export const toastMessageContainer = "ages___app_tsx-toastMessageContainer-tpg0y66";
function App({
  Component,
  pageProps,
  router
}: Record<string, any>): import('react').ReactElement {
  useProgress();
  const queryClientRef = useRef(makeAppQueryClient());
  const {
    publicRuntimeConfig
  } = getConfig();
  const siteKey = publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY || null;
  const isNotProd = publicRuntimeConfig.CF_ENV !== 'production';
  useEffect(() => {
    if (!siteKey) {
      return () => null;
    }
    const RECAPTCHA_HIDE_TIMEOUT = 10000;
    const hideRecatchaBadgeWithDelay = () => {
      const grecaptchaBadge = (document.querySelector('.grecaptcha-badge') as HTMLDivElement);
      if (grecaptchaBadge && grecaptchaBadge.style) {
        clearTimeout(timeout);
        grecaptchaBadge.style.transition = 'right 2s ease 0s';
        grecaptchaBadge.style.right = '-300px';
      } else {
        timeout = setTimeout(() => hideRecatchaBadgeWithDelay(), RECAPTCHA_HIDE_TIMEOUT);
      }
    };
    let timeout = setTimeout(() => hideRecatchaBadgeWithDelay(), RECAPTCHA_HIDE_TIMEOUT);
    return () => {
      clearTimeout(timeout);
    };
  }, [siteKey]);
  const apiErrorSubject = useMemo(createApiErrorSubject, []);
  // temporary effect to let `clarifaiFetch` access apiErrorSubject; will later be removed in favor
  // of passing apiErrorSubject from React to the API Client
  useEffect(() => {
    window.__CF__API__ERROR__SUBJECT = apiErrorSubject;
    return () => {
      window.__CF__API__ERROR__SUBJECT = undefined;
    };
  }, []);
  const stream = useMemo(() => apiErrorSubject.asObservable(), []);
  usePageTrack();
  const defaultSeoProps: {
    title: string;
    description?: string;
  } = {
    title: pageProps?.seoProps?.title || "Clarifai - The World's AI"
  };
  if (pageProps?.seoProps?.description) {
    defaultSeoProps.description = pageProps.seoProps.description;
  }
  let openGraphImage: OpenGraphMedia = {
    url: 'https://www.clarifai.com/hubfs/community-the-worlds-ai-community-featured.png',
    width: 1200,
    height: 600,
    alt: "The World's AI Community: Vision, Language, Audio and AutoML"
  };
  if (pageProps?.seoProps?.image?.url) {
    openGraphImage = (pageProps?.seoProps?.image as OpenGraphMedia);
  }
  return <SSRProvider>
      <DefaultSeo {...defaultSeoProps} additionalLinkTags={[{
      rel: 'icon',
      href: '/favicon.svg'
    }, {
      rel: 'manifest',
      href: '/manifest.json'
    }]} openGraph={{
      url: `https://www.clarifai.com${router.pathname}`,
      images: [openGraphImage]
    }} additionalMetaTags={[{
      name: 'viewport',
      content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
    }, {
      name: 'theme-color',
      content: '#317EFB'
    }]} dangerouslySetAllPagesToNoIndex={isNotProd} dangerouslySetAllPagesToNoFollow={isNotProd} />
      <QueryClientProvider client={queryClientRef.current}>
        <Hydrate state={pageProps.dehydratedState}>
          <ReCaptchaContext reCaptchaKey={siteKey}>
            <AnalyticsCollectionProvider trackEvent={pageProps?.trackEventProps}>
              <ModalProvider animated>
                <AuthProvider initialAuthData={pageProps?.defaultProps?.currentUser}>
                  <FeatureFlagsProviderLD initialAuthData={pageProps?.defaultProps?.currentUser}>
                    <TipPopoverProvider>
                      <FeaturedFlagsProvider enabledFeatures={pageProps?.defaultProps?.enabledFeatures || []}>
                        <InputsJobMonitorProvider>
                          {/* Redirect user to Settings page if their email is not validated */}
                          <RequestWatcher stream={stream} />
                          <Component {...pageProps} />
                          <ModalManager />
                          <div id={TOAST_CONTAINER_ID} className={toastMessageContainer} />
                        </InputsJobMonitorProvider>
                      </FeaturedFlagsProvider>
                    </TipPopoverProvider>
                  </FeatureFlagsProviderLD>
                </AuthProvider>
              </ModalProvider>
            </AnalyticsCollectionProvider>
          </ReCaptchaContext>
        </Hydrate>
      </QueryClientProvider>
    </SSRProvider>;
}
App.getInitialProps = async ({
  Component,
  ctx
}: Record<string, any>) => {
  let pageProps = {};
  if (Component.getInitialPrrops) {
    pageProps = await Component.getInitialProps(ctx);
  }
  return {
    pageProps
  };
};
const handleRouteChangeStart = (): void => {
  Nprogress.start();
};
const handleRouteChangeEnd = (): void => {
  Nprogress.done();
};
function useProgress(): null {
  const router = useRouter();
  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      router.events.on('routeChangeStart', handleRouteChangeStart);
      router.events.on('routeChangeComplete', handleRouteChangeEnd);
      router.events.on('routeChangeError', handleRouteChangeEnd);
      return () => {
        router.events.off('routeChangeStart', handleRouteChangeStart);
        router.events.off('routeChangeComplete', handleRouteChangeEnd);
        router.events.off('routeChangeError', handleRouteChangeEnd);
      };
    }
    return () => {};
  }, []);
  return null;
}
export default App;

require("./_app.linaria.module.css!=!../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./_app.tsx");