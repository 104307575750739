import { useCallback } from 'react';
import { noop } from 'utils/fp';
import { cx } from '@linaria/core';
import { getEntityId, getAppDefaultWorkflow, getEntityVisibility, getUserId } from 'modules/Listings/helpers/entityCardUtils';
import { Visibility } from 'components/Visibility';
import { IconDotsMenu } from 'components/Icons';
import { PopoverAction } from 'components/Popover';
import { ModelTypeIcon } from 'components/ModelTypeIcon/ModelTypeIcon';
import { TemplateBadge } from 'components/Badge/TemplateBadge';
import { cssHelpers } from 'styles/utils/core';
import { EntityCardGradient } from './EntityCardGradient';
import { EntityCardFooter } from './Footer';
import { EntityCardModelTypeIcon } from './EntityCardModelTypeIcon';
import { useShortDescription } from './utils';
import { hideBelow900, wrapper, iconContainer, titleStyles, moreActionsWrapper, detailsContainer2, iconFallback, modelTypeClass, modelTypeItemCrontainerClass, modelTypeItemIconClass, actionsWrapper, contentWrapper } from './EntityCardList.styled';
import { FooterBreadcrumbs } from './FooterBreadcrumbs';
import { EntityCardLayoutProps } from './types';
import { TagList } from './TagList';
export const EntityCardList: React.FC<EntityCardLayoutProps> = ({
  title,
  description,
  footerItems,
  cardThumbnail,
  share,
  star,
  lock,
  tags,
  owner,
  entity,
  popover,
  visibilityHidden,
  onCardClick
}) => {
  const preventPropogation = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, []);
  const visibility = getEntityVisibility(entity);
  const entityId = getEntityId(entity);
  const defaultAppWorkflowId = getAppDefaultWorkflow(entity);
  const shortDescription = useShortDescription(description, 25);
  const userId = getUserId(entity);
  const isAppTemplate = (entity as CF.API.Apps.App).is_template;
  return <div className={wrapper} onClick={onCardClick} role="button" onKeyDown={noop} tabIndex={0} title={title}>
      <div className={contentWrapper}>
        <div className={cx(iconContainer, hideBelow900)}>
          {isAppTemplate && <TemplateBadge />}
          {cardThumbnail ? <img src={cardThumbnail} className="thumbnail" alt={title} /> : <EntityCardGradient className={iconFallback} gradientText={'id' in entity ? entity.id : 'default_id'}>
              {defaultAppWorkflowId !== '' && <div style={{
            fontSize: 12,
            textAlign: 'center',
            width: '90%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
                  {defaultAppWorkflowId}
                </div>}
              {'model_type_id' in entity && <div className="iconBack">
                  <EntityCardModelTypeIcon modelTypeId={entity.model_type_id} size="90%" />
                </div>}
              {'nodes' in entity && <div className={modelTypeItemCrontainerClass}>
                  {entity.nodes?.map(node => <div className={modelTypeItemIconClass}>
                      <EntityCardModelTypeIcon modelTypeId={node.model.model_type_id} size="70%" />
                    </div>)}
                </div>}
            </EntityCardGradient>}
        </div>
        <div className={detailsContainer2}>
          <div className="flex-left-container">
            <div>
              <div className={cx('modelcard-header', 'elli')}>
                <span>{entityId}</span>
              </div>
            </div>
            <div>
              {star}
              {lock}
            </div>
          </div>
          <div className={titleStyles}>{!visibilityHidden && <Visibility visibility={visibility} text={visibility} />}</div>
          {'nodes' in entity && <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 5,
          border: '#f00 0px solid',
          paddingBottom: 0
        }}>
              {entity.nodes?.map(node => <div style={{
            display: 'block'
          }}>
                  <div className={modelTypeClass}>
                    <ModelTypeIcon modelTypeId={node.model.model_type_id} />
                  </div>
                </div>)}
            </div>}
          {'model_version' in entity && <div style={{
          position: 'relative',
          left: -4
        }}>
              <span style={{
            fontSize: '12px',
            display: 'inline-block'
          }}>
                <ModelTypeIcon modelTypeId={entity.model_type_id} />
              </span>
            </div>}
          <div style={{
          marginTop: 5,
          marginBottom: 5
        }}>
            <div className="modelcard-description">{shortDescription}</div>
          </div>
          <TagList tags={tags} />
        </div>
      </div>

      <div className={cx('flex-left-container', cssHelpers.flex.justifyContent.between, cssHelpers.flex.gap[1])}>
        <div className="modelcard-footer">
          {userId && <EntityCardFooter items={footerItems.concat({
          uniqueKey: 'FooterBreadcrumb',
          item: <FooterBreadcrumbs entity={entity} />
        }).reverse()} workflowOnly={'model_version' in entity && entity.workflow_recommended} />}
        </div>
        <div className={actionsWrapper}>
          <div className="template-mobile-container">{isAppTemplate && <TemplateBadge />}</div>
          {share}
          <div className={moreActionsWrapper} tabIndex={0} onClick={preventPropogation} onKeyDown={noop} role="button">
            {owner && <PopoverAction popover={popover}>
                <IconDotsMenu size={18} color="#808080" />
              </PopoverAction>}
          </div>
        </div>
      </div>
    </div>;
};