/* eslint-disable @typescript-eslint/no-explicit-any */
import { taskEither as TE } from 'fp-ts';
import { isString } from 'utils/fp';
import { clarifaiFetch } from './clarifaiFetch';
import { cookieClientStorage } from '../cookieStorage';

// eslint-disable-next-line @typescript-eslint/ban-types
type RequestParams<T extends object = Record<string, any>> = {
  apiKey?: string;
  apiHost?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  body?: T;
  path: string;
  sessionToken?: string;
  signal?: AbortSignal;
  useRequestPrefixHeader?: boolean;
};

// export type LazyFetcher<T, E = Error> = import('fp-ts/TaskEither').TaskEither<E, T>;
/**
 * `api` function returns a tryCatch, which is TaskEither.
 * TaskEither is a lazy future, which *** WHEN CALLED ***
 * returns an Either of the API response. i.e. Either<Response> or Left<Error>
 *
 * So, the way to consume this function is:
 * const thennable = api({ path: '/users' })(); // actually executes the promise
 *  thennable.then(data => ...)
 *    // OR //
 *  const data = await thennable;
 */

export const fetchApi = <T = unknown>(requestParams: RequestParams): Promise<T> => clarifaiFetch(makeApiConfig(requestParams)) as Promise<T>;
export interface ErrorResponse extends Error {
  status: { code: number; description: string; details: string };
}

export const fetchTE = <T = unknown, E = ErrorResponse>(
  requestParams: RequestParams,
  onRejected: (reason: any) => E = (reason) => reason, // DEFAULT onRejected, returns an Either of error
): import('fp-ts/TaskEither').TaskEither<E, T> =>
  TE.tryCatch(
    () => clarifaiFetch(makeApiConfig(requestParams)) as Promise<T>,
    // if request fails, tryCatch wraps the return value of onRejected in a Left i.e. Left<Error>
    onRejected,
  );

export const fetch = <T = unknown>(requestParams: RequestParams): Promise<T> => clarifaiFetch(makeApiConfig(requestParams)) as Promise<T>;

export const parseError = (e: Error): string => String(e);

function makeApiConfig(requestParams: {
  apiHost?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  path: string;
  apiKey?: string;
  sessionToken?: string;
  useRequestPrefixHeader?: boolean;
  signal?: AbortSignal;
  // eslint-disable-next-line @typescript-eslint/ban-types
  body?: object;
}): import('./clarifaiFetch').ClarifaiFetchParams {
  const sessionToken = requestParams.sessionToken || cookieClientStorage.get('session_token');

  return {
    apiHost: isString(requestParams.apiHost) ? requestParams.apiHost : `${process.env.NEXT_PUBLIC_HOST || ''}`,
    method: requestParams.method || 'GET',
    ...(requestParams.apiKey ? { apiKey: requestParams.apiKey } : sessionToken ? { sessionToken } : {}),
    path: requestParams.path,
    body: requestParams.body as any,
    useRequestPrefixHeader: requestParams.useRequestPrefixHeader,
    signal: requestParams.signal,
  };
}

export const testable = { makeApiConfig, parseError };
