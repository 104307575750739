import { baseTheme } from 'styles/utils';
import { IconProps } from './IconProps';

type IconMinimizeProps = IconProps & {
  strokeWidth?: number;
};

export const IconMinimize = ({ size = 20, color = baseTheme.light.colors.gray500, strokeWidth = 1.66667, ...rest }: IconMinimizeProps) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M4.16666 10H15.8333" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
