import { cx } from '@linaria/core';
import { useState, useEffect, useCallback, Dispatch, SetStateAction, useMemo } from 'react';
import { noop } from 'utils/fp';
import { UploadJobType } from 'hooks/useUploader';
import { Tabs } from 'modules/InputsJobMonitor/Tabs';
import { withAuth } from 'hocs/withAuth/withAuth';
import { Checkbox } from 'components/Checkbox';
import { IconMinimize } from 'components/Icons/IconMinimize';
import { IconCloseRound } from 'components/Icons';
import { TabStatus } from 'hocs/InputsJobMonitor/InputsJobMonitor';
import { useBaseReqParams } from 'hooks/useBaseReqParams';
import { baseTheme } from 'styles/utils/core';
import usePreventNavigationOnCondition from 'hooks/usePreventNavigationOnCondition';
import {
  inputJobsWrapperCollapsed,
  inputJobsWrapperExpanded,
  inputJobsWrapper,
  jobsContainer,
  jobsHeader,
  jobsFooter,
  title,
  minimizeIcon,
  tabItem,
  tabs,
  checkbox as checkboxCssClass,
  checkboxLabel,
  icon,
} from './JobMonitorExpanded.styles';
import { JobMonitorItem } from './JobMonitorItem';

type JobMonitorExpandedProps = {
  jobs: Record<string, UploadJobType>;
  shouldRefresh?: boolean;
  isExpanded: boolean;
  activeTab: TabStatus;
  className?: string;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  setActiveTab: Dispatch<SetStateAction<TabStatus>>;
  onCheck?: () => void;
};

export const JobMonitorExpanded_ = ({
  className,
  jobs,
  shouldRefresh,
  activeTab,
  isExpanded,
  setActiveTab,
  setIsExpanded,
  onCheck,
}: JobMonitorExpandedProps): JSX.Element | null => {
  const { appId: currentAppId } = useBaseReqParams();
  const [isClosed, setIsClosed] = useState(true);

  const [jobList, setJobList] = useState<{
    active: UploadJobType[];
    inactive: UploadJobType[];
  }>({
    active: [],
    inactive: [],
  });

  useEffect(() => {
    const activeJobs: UploadJobType[] = [];
    const inactiveJobs: UploadJobType[] = [];

    Object.values(jobs).forEach((job) => {
      if (job.finished) {
        inactiveJobs.push(job);
      } else if (!jobs[job.jobId]) {
        activeJobs.push(job);
      }
    });

    setJobList({
      active: activeJobs,
      inactive: inactiveJobs,
    });
  }, [jobs]);

  const appJobs = Object.values(jobs)
    .filter((job) => job.appId === currentAppId && !job.finished)
    .map((job) => {
      const { jobId, userOrOrgId, appId, finished, error, createdAt, inputsCount, uploadedCount, successCount, failedCount, percentage } = job;
      return {
        id: jobId,
        userOrOrgId,
        appId,
        error,
        jobId,
        finished,
        inputsCount,
        failedCount,
        uploadedCount,
        successCount,
        createdAt,
        percentage: percentage || Math.round((uploadedCount / inputsCount) * 100),
      };
    });

  appJobs.reverse();

  const allJobsFinished = useMemo(() => appJobs.every(({ finished }) => finished), [appJobs]);

  const enableClose = useCallback(() => {
    if (appJobs.some(({ finished }) => !finished)) {
      return false;
    }
    if (jobList.active.length > 0) {
      return false;
    }

    if (allJobsFinished && !isClosed) {
      return false;
    }

    return true;
  }, [appJobs, jobList, allJobsFinished]);

  useEffect(() => {
    if (!enableClose()) {
      setIsClosed(false);
    } else {
      setIsClosed(true);
    }
  }, [enableClose]);

  useEffect(() => {
    if (allJobsFinished && !isClosed) {
      setActiveTab(TabStatus.Inactive);
    }
  }, [allJobsFinished]);

  usePreventNavigationOnCondition({
    condition: (url: string) => !allJobsFinished && url.split('?')[0].split('/').pop() !== 'inputs',
    confirmMessage: 'Upload is in progress. Are you sure you want to leave this page?',
  });

  const onCollapseClick = useCallback(() => {
    setIsExpanded((v) => !v);
  }, []);

  const onCloseClick = useCallback(() => {
    if (allJobsFinished) {
      setIsClosed(true);
    }
  }, [allJobsFinished]);
  const rootContainerClassName = isExpanded ? cx(inputJobsWrapper, inputJobsWrapperExpanded) : cx(inputJobsWrapper, inputJobsWrapperCollapsed);

  if (isClosed) {
    return null;
  }

  return (
    <div className={cx(rootContainerClassName, className)} data-testid="job-monitor-container">
      <div className="paddingContainer">
        <div className={jobsHeader}>
          <div className="header" onKeyPress={noop} role="button" tabIndex={0} onClick={() => setIsExpanded((v) => !v)}>
            <span className={title}>{!allJobsFinished ? `Uploading (${jobList.active.length + appJobs.length})` : 'Uploads'}</span>
          </div>
          <div className="hidden_el pointer" onKeyPress={noop} role="button" tabIndex={0} onClick={onCollapseClick}>
            <IconMinimize className={minimizeIcon} size={20} />
          </div>
          <div className={cx('hidden_el', icon, !allJobsFinished && 'disabled')} onKeyPress={noop} role="button" tabIndex={0} onClick={onCloseClick}>
            <IconCloseRound size={20} />
          </div>
        </div>
        <div className="hidden_el">
          <Tabs
            tabs={[
              { id: TabStatus.Active, text: 'Active' },
              { id: TabStatus.Inactive, text: 'Inactive' },
            ]}
            active={activeTab}
            className={tabs}
            tabClassName={tabItem}
            setActiveTab={(tab) => setActiveTab(tab as TabStatus)}
          />
        </div>
        <div className={cx(jobsContainer, 'hidden_el')}>
          <div className="inner">
            {activeTab === 'active' ? (
              <>
                {appJobs.map((job) => (
                  <JobMonitorItem key={job.jobId} job={job} />
                ))}
                {jobList.active.map((job) => (
                  <JobMonitorItem key={job.jobId} job={job} />
                ))}
              </>
            ) : (
              <>
                {jobList.inactive.map((job) => (
                  <JobMonitorItem key={job.jobId} job={job} />
                ))}
              </>
            )}
          </div>
        </div>
        {onCheck && (
          <div className={cx(jobsFooter, 'hidden_el')}>
            <Checkbox
              className={checkboxCssClass}
              labelTextClassName={checkboxLabel}
              data-testid="job-monitor__checkbox"
              checked={shouldRefresh}
              onClick={onCheck}
            >
              Refresh when jobs finish
            </Checkbox>
          </div>
        )}
      </div>
      {!isExpanded && (
        <div className="bottomProgressContainer">
          <div
            className="bottomProgress"
            style={{
              backgroundColor: allJobsFinished ? baseTheme.light.colors.success : baseTheme.light.colors.primary600,
            }}
          />
        </div>
      )}
    </div>
  );
};

export const JobMonitorExpanded = withAuth<JobMonitorExpandedProps>({
  hideFor: 'GUEST',
  placeholder: <p>You must be logged in to access this page</p>,
})(JobMonitorExpanded_);
