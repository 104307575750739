import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const fetchTaskTE = <E = Error>(
  { userId, appId, taskId }: CF.API.LabelerTask.FetchTaskRequest,
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.LabelerTask.FetchTasksResponse> =>
  fetchTE<CF.API.LabelerTask.FetchTasksResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/tasks/${taskId}`,
      method: 'GET',
    },
    onRejected,
  );
