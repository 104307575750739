import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const updateUserMetadataTE = <E = Error>(
  { userId, path, operation, value }: { userId: string; path: string; operation: 'merge' | 'overwrite'; value: CF.API.JSON },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Users.User> =>
  fetchTE<CF.API.Users.User, E>(
    {
      path: `/api/_api/v2/users/${userId}`,
      method: 'PATCH',
      body: {
        action: {
          op: operation,
          path,
        },
        metadata: value,
      },
    },
    onRejected,
  );
