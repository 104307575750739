import { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const getCollaboratorsTE = <E = Error>(
  { appId, userId, params }: { appId: string; userId: string; params?: string },
  onRejected?: (e: Error) => E,
  signal?: AbortSignal,
): TaskEither<E, CF.API.Collaborators.FetchCollaboratorsResponse> =>
  fetchTE<CF.API.Collaborators.FetchCollaboratorsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/collaborators${params ? `?${params}` : ''}`,
      method: 'GET',
      signal,
    },
    onRejected,
  );
