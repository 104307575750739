import { deleteAppTE, transferAppTE, updateAppIdTE, updateAppVisibilityTE } from 'api/apps';
import cogoToast from 'cogo-toast';
import { either, taskEither } from 'fp-ts';
import { starAppTE } from 'api/apps/starApp';
import { unstarAppTE } from 'api/apps/unstarApp';
import { types as t, Instance, destroy } from 'mst-effect';
import { pipe, errorToReactLeft } from 'utils/fp';
import { withRunInAction } from 'utils/withRunInAction';
import { safelyRemoveFromParentIfUsedInListable } from 'modules/Listings/store/helpers';
import { identity } from 'rambda';
import { updateAppTemplate } from 'api/apps/appTemplate';

export const appMSTTypeOnly = t
  .model({
    id: t.string,
    name: t.string,
    default_language: t.string,
    user_id: t.string,
    created_at: t.string,
    modified_at: t.string,
    description: t.union(t.optional(t.string, ''), t.undefined),
    notes: t.optional(t.string, ''),
    default_workflow_id: t.optional(t.string, ''),
    is_starred: t.maybe(t.boolean),
    star_count: t.optional(t.number, 0),
    metadata: t.frozen(),
    sample_ms: t.number,
    visibility: t.model({
      gettable: t.number,
    }),
    image: t.maybe(
      t.model({
        url: t.optional(t.string, ''),
        base64: t.maybe(t.string),
        hosted: t.maybe(
          t.model({
            crossorigin: t.maybe(t.string),
            sizes: t.optional(t.array(t.string), []),
            prefix: t.optional(t.string, ''),
            suffix: t.optional(t.string, ''),
          }),
        ),
      }),
    ),
    is_template: t.optional(t.boolean, false),
  })
  .named('CFAppMSTType');

type AppActions = {
  delete: () => Promise<void>;
  transfer: () => Promise<void>;
  star: (onFinish?: () => void) => Promise<void>;
  unstar: (onFinish?: () => void) => Promise<void>;
  toggleVisibility: (onFinish?: () => void) => Promise<void>;
  toggleAppTemplate: () => Promise<void>;
};

enum VisibilityType {
  PUBLIC = 50,
  PRIVATE = 10,
}

export const appMST = t.compose(withRunInAction(), appMSTTypeOnly).actions((self) => ({
  delete: async ({ closeDeleteModal }: { closeDeleteModal: () => void }) => {
    // // call the api
    const api = await deleteAppTE({ userId: self.user_id, appId: self.id })();
    pipe(
      api,
      either.fold(
        () => {
          cogoToast.error('Could not delete the Application');
          closeDeleteModal();
        },
        () => {
          cogoToast.success(`Application ${self.name} deleted!`);
          self.runInAction(() => {
            safelyRemoveFromParentIfUsedInListable(self as typeof self & AppActions);
            destroy(self);
          });
          closeDeleteModal();
        },
      ),
    );
  },
  transfer: async ({
    newOwnerId,
    onSuccess,
    closeModal,
    newAppId,
  }: {
    newOwnerId: string;
    onSuccess: () => void;
    closeModal: () => void;
    newAppId?: string;
  }) => {
    const transferAndUpdateAppId = pipe(
      transferAppTE({ userId: self.user_id, appId: self.id, body: { new_owner_id: newOwnerId } }, errorToReactLeft),
      newAppId
        ? taskEither.chainFirstTaskK(() =>
            updateAppIdTE({ userId: newOwnerId, body: { ids: [{ id: self.id, new_id: newAppId }], action: 'overwrite' } }, errorToReactLeft),
          )
        : identity,
    );

    pipe(
      await transferAndUpdateAppId(),
      either.fold(
        () => closeModal(),
        () => {
          cogoToast.success(`Application '${newAppId || self.id}' transferred to user '${newOwnerId}' successfully!`);
          onSuccess();
          closeModal();

          self.runInAction(() => {
            safelyRemoveFromParentIfUsedInListable(self as typeof self & AppActions);
            destroy(self);
          });
        },
      ),
    );
  },
  star: async (onFinish?: () => void) => {
    self.is_starred = true;
    self.star_count += 1;
    // Call the api
    const api = await starAppTE({
      userId: self.user_id,
      body: { user_app_id: { app_id: self.id, user_id: self.user_id }, app_stars: [{ app_id: self.id }] },
    })();
    self.runInAction(() => {
      pipe(
        api,
        either.fold(
          () => {
            cogoToast.error('Could not star the app');
            self.is_starred = false;
            self.star_count -= 1;
          },
          () => null,
        ),
        () => onFinish && onFinish(),
      );
    });
  },
  unstar: async (onFinish?: () => void) => {
    self.is_starred = false;
    self.star_count -= 1;
    const api = await unstarAppTE({
      userId: self.user_id,
      body: { user_app_id: { app_id: self.id, user_id: self.user_id }, app_ids: [self.id] },
    })();
    pipe(
      api,
      either.fold(
        () => {
          cogoToast.error('Could not unstar the app');
          self.runInAction(() => {
            self.is_starred = true;
            self.star_count += 1;
          });
        },
        () => null,
      ),
      () => onFinish && onFinish(),
    );
  },
  toggleVisibility: async (onFinish?: () => void) => {
    const newVisibility = flipVisibility(self.visibility);
    const api = await updateAppVisibilityTE({ userId: self.user_id, appId: self.id, visibility: newVisibility }, errorToReactLeft)();
    pipe(
      api,
      either.fold(
        (error) => {
          const defaultMsgForPublicApp =
            'Converting App to private failed as there are public resources present! Change their visibility to private first.';
          const defaultMsgForPrivateApp = 'Failed to convert App to public.';
          const errorMsg =
            error.props?.reason || (self.visibility.gettable === VisibilityType.PUBLIC ? defaultMsgForPublicApp : defaultMsgForPrivateApp);

          cogoToast.error(errorMsg);
          if (onFinish) {
            onFinish();
          }
        },
        () => {
          self.runInAction(() => {
            self.visibility = newVisibility;
          });

          const isPublic = newVisibility.gettable.valueOf() === VisibilityType.PUBLIC;
          cogoToast.success(`App is now ${isPublic ? 'public' : 'private'}!`);

          if (onFinish) {
            onFinish();
          }
        },
      ),
    );
  },
  toggleAppTemplate: async () => {
    try {
      const isAppTemplate = `${self.name} was marked as a template. Remember that you can always change the visibility.`;
      const isNotAppTemplate = `${self.name} is not a template anymore.`;
      self.is_template = !self.is_template;
      await updateAppTemplate({ userId: self.user_id, appId: self.id, is_template: self.is_template });
      cogoToast.success(self.is_template ? isAppTemplate : isNotAppTemplate);
    } catch {
      self.runInAction(() => {
        self.is_template = !self.is_template;
        cogoToast.error(`Failed to update app template!`);
      });
    }
  },
}));

export function flipVisibility(visibility: CF.API.Visibility): CF.API.Visibility {
  const isPublic = visibility.gettable.valueOf() === VisibilityType.PUBLIC;
  const newVisibility = { gettable: isPublic ? VisibilityType.PRIVATE : VisibilityType.PUBLIC } as unknown as CF.API.Visibility;
  return newVisibility;
}

export const appOwnerMST = t.frozen({
  id: t.string,
  first_name: t.string,
  last_name: t.string,
  company_name: t.string,
  job_role: t.optional(t.string, ''),
  job_title: t.optional(t.string, ''),
  visibility: t.model({
    gettable: t.number,
  }),
});

export const collaboratorAppMSTTypeOnly = t
  .model({
    app: appMST,
    app_owner: appOwnerMST,
    created_at: t.string,
    endpoints: t.array(t.string),
    scopes: t.array(t.string),
  })
  .named('CFCollaboratorAppMSTType');

export const collaboratorAppMST = t.compose(withRunInAction(), collaboratorAppMSTTypeOnly);

export const collaboratorAppsMST = t.array(collaboratorAppMST);

export type CollaboratorAppMSTInstance = Instance<typeof collaboratorAppMST>;
