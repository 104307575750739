import type { TaskEither } from 'fp-ts/lib/TaskEither';
import { fetchTE } from 'utils/request';

export const fetchAppResourceCountsTE = <E = Error>(
  { appId, userId }: { appId: string; userId: string },
  onRejected?: (e: Error) => E,
): TaskEither<E, CF.API.Apps.AppResourceCountsResponse> =>
  fetchTE<CF.API.Apps.AppResourceCountsResponse, E>(
    {
      path: `/api/_api/v2/users/${userId}/apps/${appId}/resource_counts`,
      method: 'GET',
    },
    onRejected,
  );
